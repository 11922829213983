import Notification from './src/core.dynamics.notification';
import Newsletter from './src/core.dynamics.newsletter';
import Authentication from './src/core.dynamics.authentication';

export namespace dynamics {
    export type Access = Authentication.Access;
    export function init() : void {}
    export class notification extends Notification { }
    export class newsletter extends Newsletter { }
    export class user extends Authentication.User { }
    export class registration extends Authentication.Registration { }
}

export default dynamics;