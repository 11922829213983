import firebase from 'firebase';
// initilize core.modules
import Analytics from './analytics/index';
import Creator from './creator/index';
import Dynamics from './dynamics/index';
import Monitoring from './monitoring/index';
import Support from './support/index';
import Services from './services/index';
import Util from './utilities/index';
/**
 * core app services namespace
 */
namespace core {
  interface configuration {
    analytics?: any,
    creator?: any,
    dynamics?: any,
    monitoring?: any,
    support?: any,
    services?: Services.configuration
  }
  /**
   * initilize core app services.
   * 
   * This method should be called before use of
   * any core services.
   */
  export function init(
    config: any, 
    coreConfig?: configuration
  ) : void {
    if (firebase.apps.length === 0) {
      // Initialize Firebase if not already initilized
      firebase.initializeApp(config);
      // initilize core modules
      Analytics.init();
      Creator.init();
      Dynamics.init();
      Monitoring.init();
      Support.init();
      Services.init(coreConfig?.services);
    }
  }
  /**
   * core app analytics namespace
   */
  export import analytics = Analytics;
  /**
   * core app creator namespace
   */
  export import creator = Creator;
  /**
   * core app dynamics namespace
   */
  export import dynamics = Dynamics;
  /**
   * core app monitoring namespace
   */
  export import monitoring = Monitoring;
  /**
   * core app support namespace
   */
  export import support = Support;
  /**
   * core app services namespace
   */
  export import services = Services;
  /**
   * Core app utilities namespace
   */
  export import util = Util;
}

export default core;