import Pages from './src/core.creator.pages';
import Content from './src/core.creator.content';
import Media from './src/core.creator.media';
import Blog from './src/core.creator.blog';

 export namespace creator {
    export function init(): void {}
    export class pages extends Pages { }
    export class content extends Content { }
    export class media extends Media { }
    export class blog extends Blog { }
  }

export default creator;