
namespace Times {
    /**
     * Get time laped from current date in timelaps format.
     * @param date Date object that represents a past date. 
     */
    export function timeLaps(
        date : Date
    ) : String {
        const recieved = new Date(date);
        const currentTime = new Date();
        const duration : number = currentTime.getTime() - recieved.getTime();
        // calculate time series
        const seconds = parseInt(`${duration / 1_000}`);
        if (seconds < 30) return 'just now';
        if (seconds < 60) return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        const minutes = parseInt(`${duration / 60_000}`);
        if (minutes < 60) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        const hours = parseInt(`${duration / 3_600_000}`);
        if (hours < 24) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        const days = parseInt(`${duration / 86_400_000}`);
        if (days < 7) return `${days} day${days > 1 ? 's' : ''} ago`;
        const weeks = parseInt(`${duration / 604_800_000}`);
        if (weeks < 4) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        const months = parseInt(`${duration / 2_628_000_000}`)
        if (months < 12) return `${months} months${months > 1 ? 's' : ''} ago`;
        return recieved.toDateString();
    }
}

export default Times;