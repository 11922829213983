import {Firestore, Databases} from './core.service.database';
import {User} from './core.service.authentication';
import {Logger, Event} from '../../support/src/core.support.logger';
/**
 * Core Dynamics Notifications Namespace
 */
export interface NotificationConfiguration {}
/**
 * Interface for notification session
 */
interface Session {
    /**
     * List of session notifications
     */
    notifications: Array<Payload>
}
/**
 * Interface for notification
 */
interface Payload {
    /**
     * CNotification id
     */
    readonly id?: string,
    /**
     * CNotification title
     */
    readonly title?: string,
    /**
     * Custom notification metadata
     */
    metadata: any,
    /**
     * Target url to navigate to on notification select.
     */
    readonly target?: string,
    /**
     * CNotification state.
     * 
     * Note: Notifications states only exist on native 
     * notifications, if native notification is set
     * to `removed` it can no loger have have attached
     * lisnters or flag updates.
     */
    state?: "removed" | "added" | "modified",
    /**
     * Indiciates notification has expired.
     * 
     * Note: `expired` state is only set for 
     * native notifications.
     */
    expired?: boolean,
    /**
     * CNotification change timestamp. Indicates
     * when the notification was last changes.
     * 
     * Note: Only used in native notifications.
     */
    timestamp?: number,
    /**
     * Identifies notification as only for
     * current session.
     */
    readonly isNative?: boolean,
    /**
     * Timestamp when notification was created
     */
    readonly recieved?: number,
    /**
     * CNotification flags. Flags are only 
     * available for native notifications.
     * 
     * Flags can be used for notification that
     * track session operations.
     */
    flags?: Array<Flag>,
    /**
     * CNotification domain lock. Indiciates 
     * the scope of the notification to a perticular
     * domain, in case the app has multiple sites.
     */
    readonly domain?: string
}
/**
 * Interface for notification flag
 */
interface Flag {
    /**
     * Flag id. Unique key for each flag in
     * a native notification set by app.
     */
    id: string,
    /**
     * FLag value, custom value for flag set
     * by app.
     */
    value: any
}
/**
 * In-App Notifications API
 */
export class CNotification {
    /**
     * CNotification sesstion identifier
     */
    private static origin : string = "core.service.notifications";
    /**
     * Gets network session instance
     */
    private static get session() : Session | null 
        {return JSON.parse(`${sessionStorage.getItem(CNotification.origin)}`)}
    /**
     * Sets or updates network session data.
     * @param metadata new network session data
     */
    private static setSession(
        metadata: Session
    ): void 
        {sessionStorage.setItem(CNotification.origin, JSON.stringify(metadata))}
    /**
     * Find native notification
     * @param NotificationID Native notification id
     */
    static find(
        NotificationID: string
    ): Payload | undefined 
        {return CNotification.session?.notifications.find(({id}) => id === NotificationID)}
    /**
     * Finds native notification index on session list
     * @param NotificationID Native notification id
     */
    static findIndex(
        NotificationID: string
    ): number | undefined 
        {return CNotification.session?.notifications.findIndex(({id}) => id === NotificationID)}
    /**
     * Sets or Updates native notification
     * @param id native notification id
     */
    static setNotification(
        id: string,
        metadata: Payload
    ): void {
        let session = CNotification.session;
        if (!session) session = {notifications: []}
        const notifications = session?.notifications;
        const index = CNotification.findIndex(id);
        if (notifications && index) 
            notifications[index] = metadata;
        CNotification.setSession(session);
    }

    static getFlags(
        id: string
    ) : Array<Flag> | undefined {
        if (CNotification.expired(id)) return undefined;
        return CNotification.find(id)?.flags;
    }
    /**
     * Gets custom flag for notification
     * @param id CNotification id
     * @param flagID Flag id
     */
    static findFlag(
        id: string,
        flagID?: string
    ) : Flag | undefined {
        if (CNotification.expired(id)) return;
        const notification = CNotification.find(id);
        if (notification?.flags)
            for (const flag of notification.flags)
                if (flag.id === flagID) return flag;
        return undefined;
    }
    /**
     * Gets notification custom flag index
     * @param id CNotification id
     * @param flagID Flag id
     */
    private static findFlagIndex(
        id: string,
        flagID: string
    ): number | undefined{
        if (CNotification.expired(id)) return;
        const notification = CNotification.find(id);
        if (notification?.flags)
            for (let flagIndex = 0; 
                flagIndex < notification.flags.length; 
                flagIndex++) {
                const flag = notification.flags[flagIndex];
                if (flag.id === flagID) return flagIndex;   
            }
        return undefined;
    }
    /**
     * Updates custom flag for notification.
     * 
     * Note: Flags can only be updated if they 
     * were created with the notification, pass
     * `flags` argument to `CNotification.pushSession`
     * to register flags for notification.
     * @param id CNotification id
     * @param flag CNotification flag id
     * @param value New value for flag
     */
    static setFlag(
        id: string, 
        flagID: string,
        value: any
    ): void {
        // check if notification has expired
        if (CNotification.expired(id)) return;
        const notification = CNotification.find(id);
        if (notification) {
            if (notification?.flags) {
                const index = CNotification.findFlagIndex(id, flagID);
                if (index) {
                    notification.flags[index].value = value;
                    notification.timestamp = Date.now();
                }
            } 
            CNotification.setNotification(id, notification);
        }
    }
    /**
     * Gets notification metadata
     * @param id CNotification
     */
    static findMetadata(
        id: string
    ): any | undefined {
        const notificaiton = CNotification.find(id);
        return notificaiton?.metadata;
    }
    /**
     * Sets or Updates notification metadata
     * @param id CNotification id
     * @param metadata New metadata for notification
     */
    static setMetadata(
        id: string,
        metadata: any
    ): void {
        if (CNotification.expired(id)) return;
        const notification = CNotification.find(id);
        if (!notification) return;
        notification.metadata = {...notification.metadata, metadata};
        notification.timestamp = Date.now();
        CNotification.setNotification(id, notification);
    }
    /**
     * Initilize CNotification Services.
     * @param appHandler Function called when a new notification is envoked for
     * current user.
     */
    static async init(
        appHandler : (notification: Payload) => {}
    ): Promise<void> {
        Logger.message(CNotification.origin, 
        'Initilizing CNotification Services');
        // remove pending native notification from previous sessions
        const pending = await Firestore
        .collection('users')
        .doc(User.uid)
        .collection('notifications')
        .where('native', '==', true).get();
        pending.forEach(async function(snapshot) 
            {await snapshot.ref.delete() })
        // attach listner notifications
        Firestore.collection('users')
        .doc(User.uid)
        .collection('notifications')
        .where('domain','==', window.location.host)
        .orderBy('recieved', 'desc')
        .onSnapshot(snapshot => CNotification.attachListner(snapshot, appHandler));
    }
    /**
     * Global Lisnter for Notifications.
     * @param snapshot notification `Firestore` document
     */
    private static attachListner(
        snapshot : Databases.QuerySnapshot, 
        appHandler : (notification: Payload) => {}
    ): void {
        // check if native
        for (const document of snapshot.docChanges()) {
            if (document.doc.data()?.native) {
                // check if removal was requested
                if (document.type === 'removed') {
                    const notification = CNotification.find(document.doc.id);
                    if (notification) {
                        notification.expired = true;
                        CNotification.setNotification(document.doc.id, notification);
                    }
                }
                // native notification request let proxy lisnter handle request
                if (document.type === 'added') CNotification
                .attachNativeLisnter(appHandler, document);
            } else {
                Logger.message(origin, 
                    'CNotification Triggered');
                const notification: Payload = {
                    metadata: document.doc.data(),
                    id: document.doc.id,
                    state: document.type
                }
                // standard notification
                appHandler(notification);
            }
        }
    }
    /**
     * Checks if notification is native to current session.
     * @param id notification id
     */
    private static isNative(
        id : string
    ) : boolean {
        const notification = CNotification.find(id);
        if (notification) return true;
        return false;
    }
    /**
     * Checks synced state of native notificaiton configuration. 
     * resolves when sync is complete.
     * @param id notification id
     */
    private static waitForNativeNotificationSync(
        id : string
    ) : Promise<boolean> {
        return new Promise(
            function(resolve, reject) {
                const notification = CNotification.find(id);
                if (notification) return resolve(true);
                else {
                    let delay = setInterval(() => {
                        if (CNotification.session) {
                            clearInterval(delay);
                            return resolve(true);
                        }
                    }, 50);
                }
            }
        )
    }
    /**
     * Attach a lisnter for running native notification.
     * @param handler Function called when changes are triggered
     * @param snapshot `Database.DocumentChange` collection
     */
    private static async attachNativeLisnter(
        handler : Function, 
        snapshot : Databases.DocumentChange
    ): Promise<void> {
        Logger.message('notifications', 
        'Native CNotification Triggered');
        // await for native notification to finish configuration
        await CNotification.waitForNativeNotificationSync(snapshot.doc.id);
        // set interval to listen for changes
        let prev : any = null;
        let listner = setInterval(() => {
            try {
                const next = CNotification.find(snapshot.doc.id);
                // filtered metadata
                const metadata : Payload = {
                    state: "removed",
                    metadata: next?.metadata,
                    id: next?.id
                };
                if (CNotification.expired(snapshot.doc.id)) {
                    Logger.message(CNotification.origin, 
                        'Native CNotification Expired');
                    // native notification was removed or it finished
                    clearInterval(listner);
                    // notifiy app of removal
                    metadata.state = 'removed';
                    handler(metadata);
                    return;
                }
                if (!prev || next?.timestamp !== prev.timestamp) {
                    Logger.message('notifications', 
                    'Native CNotification Updated');
                    // update previous state
                    metadata.state = !prev ? 'added' : 'modified';
                    prev = next;
                    // inform app handler of change
                    if (next) handler(metadata);
                }
            } catch (error: any) {
                clearInterval(listner);
                Logger.error({
                    code: "",
                    origin: CNotification.origin,
                    description: error
                } as Event);
            }
        }, 50);
    }
    /**
     * Checks if notification has expired.
     * @param id CNotification id
     */
    static expired(
        id : string
    ) : boolean {
        const notification = CNotification.find(id);
        if (notification && notification.expired) return true;
        return false;
    }
    /**
     * Attach lisnter to native notification.
     * @param id CNotification id
     * @param handler Function called when change
     * is triggered.
     */
    static onChange(
        id : string, 
        handler : Function
    ): Promise<void> {
        return new Promise(
            function(resolve, reject) {
                // check notification validity
                if (!CNotification.isNative(id)) 
                    return reject('not-found');
                // set interval to listen for changes
                let prev : Payload | undefined;
                let listner = setInterval(() => {
                    try {
                        const next = CNotification.find(id);
                        if (CNotification.expired(id)) {
                            Logger.message(CNotification.origin, 
                            'Native CNotification Expired');
                            // native notification was removed or it finished
                            clearInterval(listner);
                            // notifiy app of removal
                            handler(next?.flags);
                            return;
                        }
                        if (!prev || next?.timestamp !== prev.timestamp) {
                            // update previous state
                            prev = next;
                            // inform app handler of change
                            if (next) handler(next.flags);
                        }
                    } catch (error: any) {
                        clearInterval(listner);
                        Logger.error({
                            code: "",
                            origin: CNotification.origin,
                            description: error
                        } as Event);
                        return reject(error);
                    }
                }, 50);
            }
        )
    }
    /**
     * Creates custom notification native to application.
     * @param title CNotification title
     * @param flags CNotification custom flags
     * @param metadata CNotification custom metadata
     */
    static pushSession(
        title: string,
        flags: any,
        metadata?: any
    ) : Promise<string> {
        return new Promise(
            async function(resolve, reject) {
                // append native flag
                const notification : Payload = {
                    title: title,
                    metadata: metadata,
                    isNative: true,
                    recieved: Date.now(),
                    domain: window.location.host
                }
                // inform request
                const nativeNotification = await Firestore
                .collection('users')
                .doc(User.uid)
                .collection('notifications')
                .add(notification);
                // set local metadata
                let session = CNotification.session || {notifications: []};
                const notifications = session?.notifications || [];
                // append native notification requst
                notifications.push({
                    id: nativeNotification.id,
                    metadata: metadata,
                    flags: flags,
                    timestamp: Date.now()
                })
                CNotification.setSession(session);
                return resolve(nativeNotification.id);
            }
        )
    }
    /**
     * Push new notification.
     * @param title CNotification title
     * @param metadata Custom notification metadata
     * @param currentDevice Ignores notification
     * on other domains registered with application.
     */
    static push(
        title: string,
        metadata?: any, 
        currentDomain: boolean = true
    ) {
        return new Promise(
            async function(resolve, reject) {
                const notification : Payload = {
                    title: title,
                    recieved: Date.now(),
                    metadata: metadata,
                    domain: currentDomain
                    ? window.location.hostname
                    : undefined
                }
                // write to global collection
                await Firestore
                .collection('users')
                .doc(User.uid)
                .collection('notifications')
                .add(notification);
                return resolve(true);
            }
        )
    }
    /**
     * Removes a notification.
     * 
     * Note: if no id is sepcified all the users notifications will be cleared.
     */
    static delete(
        id : string
    ): Promise<boolean> {
        return new Promise(
            async function(resolve, reject) {
                const collection = Firestore
                .collection('users')
                .doc(User.uid)
                .collection('notifications');
                let batch = null;
                // remove notification is sepcified
                if (id) await collection.doc(id).delete();
                else {
                    const documents = await collection.get();
                    // clear all notifications
                    for (const notification of documents.docs)
                        batch = collection.firestore.batch()
                        .delete(notification.ref);
                    // commit delete
                    await batch?.commit();
                }
                return resolve(true);
            }
        )
    }
}