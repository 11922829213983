import {Firestore} from './core.service.database';

export namespace Newsletters {
    export interface configuration {}
    export function init(config: configuration) {}
}
export class Newsletter {
    /** 
     * Subscribe email to app newsletter listing.
     * @param email email address to add to list of
     * newsletter subscribers
    */
    static async Subscribe(
        email : string
    ) : Promise<boolean> {
        // Get Newsletter Collection refference
        let newsletterCollection = Firestore
        .collection("newsletter");
        // Get entire email listing
        const collection = await newsletterCollection
        .where("email", '==', email).get();
        if (collection.empty) {
            newsletterCollection.add({
                email : email,
                timestamp: Firestore.serverTimestamp
            })
            return Promise.resolve(true);
        } else 
            return Promise.reject("email already subscribed");
    }
}