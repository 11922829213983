import Dates from './src/core.util.date';
import Times from './src/core.util.time';
import Network from './src/core.util.network';
import {Database} from './src/core.util.database';
import Storage from './src/core.util.storage';
import Notifications from './src/core.util.notification';
// export renamed mobules
namespace util {
    export import date = Dates;
    export class cdate extends Dates.CDate {}
    export import time = Times;
    export class database extends Database {}
    export class storage extends Storage {}
    export class network extends Network {}
    export import notifications = Notifications;
}

export default util;