import firebase from 'firebase';
import Network from '../../utilities/src/core.util.network';
import {Logger} from '../../support/src/core.support.logger';
/**
 * Core Database Services
 */
export namespace Databases {
    // Renamed database types
    export interface firestore {
        emulator?: {
            host?: string,
            port?: number
        },
        settings?: firebase.firestore.Settings
    }
    export interface realtime {
        emulator?: {
            host?: string,
            port?: number
        }
    }
    export interface configuration {
        firestore?: firestore,
        realtime?: realtime
    }
    export type DocumentData = firebase.firestore.DocumentData;
    export type DataSnapshot = firebase.database.DataSnapshot;
    export interface DocumentChange extends firebase.firestore.DocumentChange {}
    export type QuerySnapshot = firebase.firestore.QuerySnapshot;
    export function init(config?: configuration): void {
        Firestore.init(config?.firestore);
        Realtime.init(config?.realtime);
    }
}
/**
 * Core Global Database Services.
 */
export class Firestore {
    /**
     * Get Database current refference
     */
    static get fieldValue() { return firebase.firestore.FieldValue }
    /**
     * Creates a new timestamp from the given date.
     */
        static fromDate(date : Date) : firebase.firestore.Timestamp
        {return firebase.firestore.Timestamp.fromDate(date)}
    /**
     * Returns a sentinel used with set() or update() to 
     * include a server-generated timestamp in the written data.
     */
    static get serverTimestamp() : firebase.firestore.FieldValue 
    {return firebase.firestore.FieldValue.serverTimestamp()}
    /**
     * initilize realtime database services.
     */
    static init(
        config?: Databases.firestore
    ): void {
        // initilize firebase functions
        if (!Network.inProductionEnviroment) {
            const host = config?.emulator?.host
            || "localhost";
            const port = config?.emulator?.port || 8080
            firebase.firestore().useEmulator(host, port);
            Logger.message('core.service.firestore', 
            `Development Mode Initilized, Local Instance: http://${host}:${port}`);
        }
    }
    /**
     * Specifies custom settings to be used to 
     * configure the Firestore instance. 
     * Must be set before invoking any other methods.
     */
        static settings(
        settings: firebase.firestore.Settings
    ): void { firebase.firestore().settings(settings) }
    /**
     * Gets a CollectionReference instance that 
     * refers to the collection at the specified path.
     * @param collectionPath — A slash-separated 
     * path to a collection.
     */
    static collection(
        collectionPath : string
    ) : firebase.firestore.CollectionReference<
        firebase.firestore.DocumentData> {
        return firebase.firestore()
        .collection(collectionPath);
    }
    /**
     * Gets a DocumentReference instance that refers 
     * to the document at the specified path.
     * @param documentPath — A slash-separated path 
     * to a document.
     */
    static doc(
        documentPath: string
    ) : firebase.firestore.DocumentReference<
        firebase.firestore.DocumentData> {
        return firebase.firestore().doc(documentPath);
    }
}
/**
 * Firebase Realtime Database
 */
export class Realtime {
    /**
     * initilize realtime database services.
     */
    static init(
        config?: Databases.realtime
    ): void {
        // initilize firebase functions
        if (!Network.inProductionEnviroment) {
            const host = config?.emulator?.host
            || "localhost";
            const port = config?.emulator?.port || 9000
            firebase.database().useEmulator(host, port);
            Logger.message('core.service.realtime', 
            `Development Mode Initilized, Local Instance: http://${host}:${port}`);
        }
    }
    /**
     * Returns a `Reference` representing the location in the Database
     * corresponding to the provided path. If no path is provided, the `Reference`
     * will point to the root of the Database.
     *
     * @example
     * ```javascript
     * // Get a reference to the root of the Database
     * var rootRef = Database.ref();
     * ```
     *
     * @example
     * ```javascript
     * // Get a reference to the /users/ada node
     * var adaRef = Database.ref("users/ada");
     * // The above is shorthand for the following operations:
     * //var rootRef = Database.ref();
     * //var adaRef = rootRef.child("users/ada");
     * ```
     * @return If a path is provided, a `Reference`
     *   pointing to the provided path. Otherwise, a `Reference` pointing to the
     *   root of the Database.
     */
        static ref(
        path?: string
    ) : firebase.database.Reference {
        // return database refference for path if provided
        if (path) return firebase.database().ref(path);
        return firebase.database().ref();
    }
}
/**
 * The set of Database status codes.
 * 
 * - 'cancelled': The operation was cancelled (typically by the caller).
 * - 'unknown': Unknown error or an error from a different error domain.
 * - 'invalid-argument': Client specified an invalid argument. Note that this
 *   differs from 'failed-precondition'. 'invalid-argument' indicates
 *   arguments that are problematic regardless of the state of the system
 *   (e.g. an invalid field name).
 * - 'deadline-exceeded': Deadline expired before operation could complete.
 *   For operations that change the state of the system, this error may be
 *   returned even if the operation has completed successfully. For example,
 *   a successful response from a server could have been delayed long enough
 *   for the deadline to expire.
 * - 'not-found': Some requested document was not found.
 * - 'already-exists': Some document that we attempted to create already
 *   exists.
 * - 'permission-denied': The caller does not have permission to execute the
 *   specified operation.
 * - 'resource-exhausted': Some resource has been exhausted, perhaps a
 *   per-user quota, or perhaps the entire file system is out of space.
 * - 'failed-precondition': Operation was rejected because the system is not
 *   in a state required for the operation's execution.
 * - 'aborted': The operation was aborted, typically due to a concurrency
 *   issue like transaction aborts, etc.
 * - 'out-of-range': Operation was attempted past the valid range.
 * - 'unimplemented': Operation is not implemented or not supported/enabled.
 * - 'internal': Internal errors. Means some invariants expected by
 *   underlying system has been broken. If you see one of these errors,
 *   something is very broken.
 * - 'unavailable': The service is currently unavailable. This is most likely
 *   a transient condition and may be corrected by retrying with a backoff.
 * - 'data-loss': Unrecoverable data loss or corruption.
 * - 'unauthenticated': The request does not have valid authentication
 *   credentials for the operation.
 */
export type DatabaseErrorCode =
| 'cancelled'
| 'unknown'
| 'invalid-argument'
| 'deadline-exceeded'
| 'not-found'
| 'already-exists'
| 'permission-denied'
| 'resource-exhausted'
| 'failed-precondition'
| 'aborted'
| 'out-of-range'
| 'unimplemented'
| 'internal'
| 'unavailable'
| 'data-loss'
| 'unauthenticated';
/** An error returned by a Database operation. */
export interface DatabaseError {
    code: DatabaseErrorCode;
    message: string;
    name: string;
    stack?: string;
}