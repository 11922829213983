/**
 * Authentication Dynamics Library.
 * 
 * Authentication dynamics allows controling
 * of user profies and other authentication
 * services.
 */
namespace Authentication {
    /**
     * User Operation Access
     */
    export enum Access {
        /**
         * Create access to content
         */
        Create = "CREATE",
        /**
         * Read access to content.
         */
        Read = "READ",
        /**
         * Update access to content.
         */
        Update = "UPDATE",
        /**
         * Delete access to content.
         */
        Delete = "DELETE"
    }
    /**
     * User Operation Interface.
     */
    export interface Operation {
        /**
         * Operation id
         */
        id: string,
        /**
         * Operation Scope, identifies the root
         * application funtionality, normally reffers
         * to Epics.
         */
        scope: string,
        /**
         * Operation designation, identifies the specific
         * feature of the application that the operation
         * belongs to.
         */
        metadata: any,
        /**
         * Operation access, identifie the list of 
         * available access controls on operation for 
         * specific user groups
         */
        access: Access
    }
    /**
     * Global User Group Interfae.
     */
    export interface Group {
        /**
         * Role id. Preset user role id, automatically
         * set by auth services on role assignment.
         */
        readonly id: string,
        /**
         * Global user group name.
         */
        readonly name: string,
        /**
         * Global user group access level, numeric access
         * designation for users in the global user group,
         * `access level` is used by `core` services to
         * filter service requests by end-users.
         */
        readonly level: number,
        /**
         * List of operations not allowed for user group.
         * Identified operations will apply to all users
         * within the user group. If an operation is not
         * in this list it will be automatically be regarded
         * as allowed.
         */
        readonly deny?: Array<Operation>,
        /**
         * List of operations allowed for user group.
         * Identified operations will to all users within the
         * user group. If an operation is not in this list it 
         * will regarded as denied.
         */
        readonly allow?: Array<Operation>,
        /**
         * Number of users assigned to group.
         */
        readonly count?: number
    }
    /**
     * Dynamic User Roles.
     * 
     * User Roles API allows managing 
     * custom defined user roles for a global
     * group of users, this allows defining
     * filters for restricting content access.
     */
    export class Groups {
        /**
         * Global User Group id(s)
         */
        //private readonly groupID: string | Array<string> | undefined;
        /**
         * Initilize global user group collection.
         * @param groupID Global User Group id
         */
        // constructor(
        //     groupID?: string | Array<string>
        // ) { this.groupID = groupID }
        /**
         * Create new user role group.
         * @param name Glbal user group name
         * @param level User group access level.
         * 
         * This value has to be lower then
         * the current users lowest access level.
         * Users can not create groups with lower
         * access level then thier own.
         */
        // create(
        //     name: string,
        //     level: number
        // ): Promise<void> {}
        /**
         * Get all defined global role groups.
         * @param userID User account id.
         * If set, all global user groups that
         * have the identified user(s) as members
         * will be returned.
         * @param roleID Global user group id.
         * If set, will return the requested
         * global user groups, will return all
         * groups otherwise.
         */
        // list(
        //     userID?: string | Array<string>
        // ): Promise<Array<Group>> {}
        /**
         * Get specific global user role group.
         * @param userID User id.
         * If not id is set the current users id will
         * be used.
         */
        // get(
        //     roleID: string
        // ): Promise<Group> {}
        /**
         * Add user(a) to global user group(s)
         * @param userID User id to add to role goup
         * @param roleID Global role group id(s) to add
         * user to.
         */
        // assign(
        //     userID: string | Array<string>
        // ): Promise<void> {}
        /**
         * Unassign user(s) account from global user group(s).
         * @param userID User acctoun id
         * @param roleID Global role group id.
         * If no GRG id is set, the user will be removed
         * from all assigned groups.
         */
        // unassign(
        //     userID: string | Array<string>
        // ): Promise<void> {}
        /**
         * Check if user is allowed access to
         * the requested operation.
         * @param operation Operation id to reffer to.
         */
        // isAllowed(
        //     operationID: Operation
        // ): Promise<boolean> {}
        /**
         * Update global user role group.
         * @param roleID Global user group id
         * @param role Updated role metadata
         */
        // update(
        //     name: string,
        //     level?: number
        // ): Promise<void> {}
        /**
         * Delete global role group.
         * Upon successful deletion of global user group,
         * all assigned users will also be removed.
         * @param roleID Global user group
         */
        // delete(): Promise<void> {}
    }
    /**
     * Dynamic User Registration
     */
    export class Registration {
        /**
         * Register new user.
         * 
         * When dynamic users are created thier accounts
         * state are set to `in-complete`, in this state, it wont allow
         * the user account to be part of any operations until
         * the state is changed to `complete`, which will only be done
         * when the user loges in and completes the registration.
         * @param email User account email address.
         * If email already belongs to an account an error will be 
         * thrown.
         * @param key Registration confirmation key.
         * By default a key will be generated for the user and sent
         * in the registration request email.
         * @param role Global user group the user account will be
         * added to.
         */
        // create(
        //     email: string, 
        //     key?: string,
        //     role?: string
        // ): Promise<void> {}
        /**
         * Complete user registration.
         * 
         * Once the user is registered succesfuly the account status will
         * be set to `complete` and the user will be allowed to access
         * application content.
         * @param email Users registered email address.
         * Email address must match the address used to create the initial
         * registration for the user.
         * @param key Registration confirmation key
         * The password that was assigned to the user during registration,
         * this key is used for registration confirmation and
         * will be set as the users default password.
         */
        // complete(
        //     email: string,
        //     key: string
        // ): Promise<void> {}
        /**
         * Update user account status.
         * After succesful status change of user account, an
         * email will be sent to the account email address 
         * informaing of the change, the email templete will
         * change depending on the state type.
         * @param state New account state
         */
        // updateState(
        //     state: core.Status
        // ): Promise<void> {}
    }
    /**
     * User Dynamics API.
     * 
     * This API is used to manage user accounts
     * on a `firebase.auth` namespace.
     * User Dynamics is locked to users
     * with `access levels` of `1`, any account
     * accessing user dynamic services that 
     * have an `access level` greater then `1`
     * will be denyed and reported.
     */
    export class User {
        /**
         * Get list of active user accounts.
         */
        static list() {}
        static logs() {}
        static update() {}
        static delete() {}
        static registration(): Registration 
            {return new Registration()}
        // static groups(
        //     groupID?: string | Array<string>
        // ): Groups 
        //     {return new Groups(groupID)}
    }
}

export default Authentication;