import firebase from 'firebase';
import {Logger} from '../../support/src/core.support.logger';
import {Database} from '../../utilities/src/core.util.database';
import Network from '../../utilities/src/core.util.network';
import { Firestore } from './core.service.database';

export namespace Storage {
    export const origin = "core.services.storage";
    export interface configuration {
        emulator?: {
            host?: string,
            port?: number
        }
    }
    // Global Storage Upload State Payload
    export enum TaskState {
        PAUSED = 'PAUSED',
        CANCELED = 'CANCELED',
        SUCCESS = 'SUCCESS',
        RUNNING = 'RUNNING',
        ERROR = 'ERROR',
        COMPLETED = 'COMPLETED'
    }

    export interface UploadTaskState {
        metadata: { name: String },
        bytesTransfered: number,
        totalBytes: number,
        progress: number,
        currentState: TaskState,
        error: { 
            state: Boolean, 
            code: String,
            message: String
        },
        pause: Function,
        cancle:  Function,
        resume:  Function
    }
    /**
     * Interface for Cloud Storage Instanve
     */
    export interface Quota {
        /**
         * Total available storage available for project's
         * storage instance
         */
        total_available: number,
        /**
         * Total storage used for default storage bucket
         */
        total_used: number
    }
    /**
     * initilize realtime database services.
     */
    export function init(
        config?: configuration
    ): void {
        // initilize firebase functions
        if (!Network.inProductionEnviroment) {
            const host = config?.emulator?.host
            || "localhost";
            const port = config?.emulator?.port || 9199;
            firebase.storage().useEmulator(host, port);
            Logger.message(Storage.origin, 
            `Development Mode Initilized, Local Instance: http://${host}:${port}`);
        }
        // initilize Local storage instance
        GCPStorage.init();
    }
}
/**
 * Offline Storage Services
 */
class Offline {
    /**
     * User local database instance
     */
    static get database(): Database
    {return new Database(Storage.origin)}
        /**
     * Download file from GCPStorage storage and return as blob.
     * 
     * Note: blob object can only be fetched if the GCPStorage storage
     * bucket containg the object allows cross-origin-access to either
     * the development server or the production domain. It is important
     * to set CORS on storage bucket before calling this method
     */
    static getObjectAsBlob(
        objectPath : string, 
        cache? : boolean
    ) : Promise<Blob> {
        return new Promise(
            async function(resolve, reject) {
                const database = Offline.database;
                // get a refference to the local objects
                const table = database.table('objects');
                let object = firebase.storage().ref(objectPath);
                let metadata = await object.getMetadata()
                    .catch(function() {reject('object-not-found')});
                //check if object is already in cache
                let localObject = await table.index('path')
                .where("path",'==', objectPath).get();
                if (!localObject.empty) {
                    const data = localObject.getAll().pop()?.data;
                    // object already in cache
                    // check if its up to date
                    if (metadata.updated === data.updated)
                        return resolve(data.blob);
                }
                let fileURL = await object.getDownloadURL()
                    .catch(function() {reject('object-not-found')});
                let blob = await Offline.toBlob(fileURL, metadata.contentType)
                if (cache)
                    // store or update local record
                    table.add({
                        path: objectPath,
                        blob: blob,
                        size: metadata.size,
                        timeCreated: metadata.timeCreated,
                        updated: metadata.updated,
                        name: metadata.name,
                        contentType: metadata.contentType,
                        type: metadata.type
                    })
                return resolve(blob);
            }
        )
    }
    /**
     * Attempt Convertion of file to blob.
     */
    private static toBlob(
        url : string, 
        type : string, 
        metadata? : any
    ) : Promise<Blob> {
        return new Promise(
            async function(resolve, reject) {
                // test image file
                if (RegExp('([a-zA-Z0-9s_\\.-:])+(.png|.jpe?g)$','i').test(type)) {
                    // convert image
                    let img : HTMLImageElement = new Image();
                    img.crossOrigin = "";
                    img.src = url;
                    let canvas = document.createElement("canvas");
                    let ctx = canvas.getContext("2d");
                    img.onload = function() {
                        // resize img to required resolution if provided
                        canvas.width = metadata?.width ? metadata.width : img.naturalWidth;
                        canvas.height = metadata?.height ? metadata.height : img.naturalHeight;
                        // draw in imagz
                        if (ctx) ctx.drawImage(img, 0, 0);
                        // get content as JPEG blob
                        canvas.toBlob(function(blob) {
                            if (blob) return resolve(blob);
                            else return new Blob();
                        }, type, 0.75);
                    };
                }
            }
        )
    }
}
/**
 * @summary Insight Storage Service
 */
export class GCPStorage {
    /**
     * initilize realtime database services.
     */
    static async init(): Promise<void> {
        Logger.message(Storage.origin,
        'Initilizing GCPStorage Services');
        // get database object
        const database = Offline.database;
        //initilize messaging api
        const table = database.table('objects');
        if (!(await table.exists())) {
            //create table
            await table.create();
            // create table index
            await table.index('path').create("path", true);
        }
    }
    /**
     * Get total storage used for current app instance. The 
     * total size returned will be in bytes.
     */
    static async getTotalUsed(): Promise<number> {
        // fetch from quota metadata
        const quota = await Firestore.doc('core/services/storage/quota').get();
        if (quota.exists)
            return quota.data()?.total_used || 0;
        // storage quota has not been initilized yet
        return 0;
    }
    /**
     * Get total storage quota available for current app instance.
     * The storage quota will company with GCP storage quota depending
     * on the billing account for the app instance.
     */
    static async getTotalAvailable(): Promise<number> {
        // fetch from quota metadata
        const quota = await Firestore.doc('core/services/storage/quota').get();
        if (quota.exists)
            return quota.data()?.total_available || 5000_000_000;
        // storage quota has not been initilized yet
        // asume free plan is selected
        return 5000_000_000;
    }
    /**
     * Attach lisnter for cloud storage quota changes
     */
    static async onQuotaUpdated(
        lisnter: (quota: Storage.Quota) => void
    ) {
        // fetch from quota metadata
        Firestore.doc('core/services/storage/quota')
        .onSnapshot(snap => lisnter(snap?.data() as Storage.Quota))
    }
    /**
     * Returns senatized path
     */
    static getSenitizedPath(
        path : string
    ) : string 
    {return path.split(' ').join('_')}
    
    /**
     * Checks if GCPStorage storage contains object at path, if exits it will
     * return the object metadata, false otherwise.
     */
    static hasObject(
        path : string
    ) : Promise<any> {
        return new Promise(
            async function(resolve, reject) {
                // get file metadata if exists
                firebase.storage()
                .ref(path).getMetadata()
                .then(metadata => resolve(metadata), _ => resolve(false))
            }
        )
    }
    
    /**
     * Checks if object exists in GCPStorage storage.
     */
    static exists(
        path : string
    ) : Promise<boolean> {
        return new Promise(
            async function(resolve, reject) {
                firebase.storage().ref(path).getMetadata()
                .then(_ => resolve(true), _ => resolve(false))
            }
        )
    }

    /**
     * Gets GCPStorage storage object metadata.
     */
    static getMetadata(
        path : string
    ): Promise<any> {
        return new Promise(
            async function(resolve, reject) {
                const metadata = await firebase.storage()
                .ref(path).getMetadata()
                .catch(error => {GCPStorage.generateError(error)})
                resolve(metadata);
            }
        )
    }
    /**
     * Deletes file from GCPStorage storag. Note if 
     * object was cached locally it will also be removed.
     */
    static delete(
        objectPath : string
    ): Promise<void> {
        return new Promise(
            async function(resolve, reject) {
                //const database = Offline.database;
                // get local storage objects
                //const table = database.table('objects');
                // get object metadata
                const object = firebase.storage().ref(objectPath);
                // get storage object metadata
                await object.getMetadata().then(async metadata => {
                    // update storage quota
                    // await Firestore.doc('core/services/storage/quota').update({
                    //     total_used: Firestore.fieldValue.increment(-metadata.size)
                    // })
                });
                // delete object
                await object.delete().catch(function() 
                    { return reject("object-does-not-exist") })
                // check if object was cached locally
                //const localObject = await table.index("path").is("==", objectPath);
                // delete local cache aswell
                //if (!localObject.empty) await localObject.batchDelete();
                return resolve();
            }
        )
    }
    /**
     * Replace the destination file with new one.
     */
    static async update(
        destination : string, 
        file : File, 
        metadata? : any, 
        listner? : (taskState: Storage.UploadTaskState) => void
    ) : Promise<string> {
        return new Promise(
            async function(resolve, reject) {
                // delete destination file
                const objectDoesExist = await GCPStorage.exists(destination);
                if (objectDoesExist) await GCPStorage.delete(destination);
                // upload new file
                // split objects in the path
                const objects = destination.split('/');
                // remove destination file
                const path = `${objects.splice(0, objects.length-1).join('/')}`;
                // upload new file
                await GCPStorage.uploadFile(file, path, metadata, listner);
                return resolve(`${path}/${file.name}`)
            }
        )
    }
    /**
     * Upload operation state lisnter
     */
    private static onUpload(
        operation : firebase.storage.UploadTask, 
        onFileUpload? : (taskState: Storage.UploadTaskState) => void
    ) : Promise<Boolean> {
        return new Promise(
            async function(resolve, reject) {
                // operation state
                let taskState : Storage.UploadTaskState = {
                    metadata: {name: ''},
                    bytesTransfered: 0,
                    totalBytes: 0,
                    progress: 0,
                    currentState: Storage.TaskState.RUNNING,
                    error: { state: false, code: '', message: '' },
                    pause: () => {operation.pause()},
                    cancle:  () => {operation.cancel()},
                    resume:  () => {operation.resume()}
                }
                // set state observer for upload task
                operation.on(firebase.storage.TaskEvent.STATE_CHANGED,
                    // task state observer
                    function(snapshot) {
                        // get storage object metadata
                        taskState.metadata = snapshot.metadata;
                        taskState.metadata.name = snapshot.ref.name;
                        // get total bytes transfered
                        taskState.bytesTransfered = snapshot.bytesTransferred;
                        // get total bytes to transfer
                        taskState.totalBytes = snapshot.totalBytes;
                        // calculate percentage uploaded
                        taskState.progress = (taskState.bytesTransfered / taskState.totalBytes) * 100;
                        // identify task state
                        taskState.currentState = GCPStorage.getTaskState(snapshot);
                        // check if state obersver handler was set
                        if (typeof onFileUpload === "function")
                            // notify task state
                            onFileUpload(taskState);
                    },
                    // task encountered errors
                    function(error : any) {
                        // report error through provided state observer handler
                        taskState.error.state = true;
                        // embed error details
                        taskState.error.code = error.code;
                        taskState.error.message = error.message;
                        // perform insight logging
                        GCPStorage.generateError(error);
                        // check if state obersver handler was set
                        if (typeof onFileUpload === "function")
                            // notify task state
                            onFileUpload(taskState);
                        return reject(false);
                    },
                    // task completed successfully
                    function() {
                        taskState.currentState = Storage.TaskState.COMPLETED;
                        // notify completiong
                        // check if state obersver handler was set
                        if (typeof onFileUpload === "function")
                            // notify task state
                            onFileUpload(taskState);
                        return resolve(true);
                    })
            }
        )
    }

    /**
     * Upload file to GCPStorage storage.
     */
    static async uploadFile(
        file : File, 
        path : string, 
        metadata? : any, 
        appListner? : (taskState: Storage.UploadTaskState) => void
    ) : Promise<string> {
        return new Promise(
            async function(resolve, reject) {
                const name = GCPStorage.getSenitizedPath(file.name);
                // assign the root stroage refference
                let storageRef = firebase.storage().ref();
                // add file metadata
                const customMetadata = {contentType: file.type, customMetadata: metadata};
                // file upload refference
                let uploadTask = storageRef.child(`${path}/${name}`)
                .put(file,customMetadata);
                // set state observer for upload task
                await GCPStorage.onUpload(uploadTask, appListner)
                // handle errors
                .catch(error => reject(GCPStorage.generateError(error)))
                // update storage quota
                // await Firestore.doc('core/services/storage/quota').update({
                //     total_used: Firestore.fieldValue.increment(file.size)
                // })
                // upload complete
                return resolve(`${path}/${name}`);
            }
        )
    }
    /**
     * Idenfity upload task state.
     */
    static getTaskState(
        snapshot : firebase.storage.UploadTaskSnapshot
    ) : Storage.TaskState {
        // classify task state
        switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
                return Storage.TaskState.PAUSED;
            case firebase.storage.TaskState.CANCELED:
                return Storage.TaskState.CANCELED;
            case firebase.storage.TaskState.SUCCESS:
                return Storage.TaskState.SUCCESS;
            case firebase.storage.TaskState.RUNNING:
                return Storage.TaskState.RUNNING;
            default:
                return Storage.TaskState.ERROR;
        }
    }
    /**
     * Fetches a long lived download URL for this object. 
     */
    static async getDownloadURL(
        filePath : string
    ) : Promise<string> {
        return await firebase.storage()
        .ref(filePath).getDownloadURL()
        .catch(function(error) 
            {return Promise.reject(GCPStorage.generateError(error))});
    }
    /**
     * Gets image object from GCPStorage storage and returns as an 
     * HTMLImageElement.
     * 
     * if cache is enabled the object will be downloaded and 
     * cached on local device with the relative
     * path as the local object id, apon next request of the 
     * object, the static url of the cached object 
     * will be returned. The local object will be synced with 
     * its global counterpart.
     * 
     * Note: if the path of the global object is changed the local 
     * copy will be desynced and the object will
     * be re-downloaed and cached with the new path. However the 
     * previous object will not be removed until mannually requested.
     * 
     * 
     * Important: caching of objects is only allowed on image files.
     */
    static getImageObject(
        objectPath : string, 
        cache? : boolean
    ) : Promise<HTMLImageElement> {
        return new Promise(
            async function(resolve, reject) {
                let blob = await Offline.getObjectAsBlob(objectPath, cache);
                let urlCreator = window.URL || window.webkitURL;
                let imageUrl = urlCreator.createObjectURL(blob);
                let img = new Image();
                img.src = imageUrl;
                img.onerror = error => { return reject(error) }
                img.onload = _ => { return resolve(img) };
            }
        )
    }
    /**
     * Gets storage object at relative path and returns static 
     * url for rendering.
     * 
     * if cache is enabled the object will be downloaded and 
     * cached on local device with the relative
     * path as the local object id, apon next request of the 
     * object, the static url of the cached object 
     * will be returned. The local object will be synced with 
     * its global counterpart.
     * 
     * Note: if the path of the global object is changed the 
     * local copy will be desynced and the object will
     * be re-downloaed and cached with the new path. However 
     * the previous object will not be removed until mannually requested.
     * 
     * 
     * Important: caching of objects is only allowed on image files.
     */
    static getObjectURL(
        objectPath : string, 
        cache? : boolean
    ) : Promise<string> {
        return new Promise(
            async function(resolve, reject) {
                let blob = await Offline.getObjectAsBlob(objectPath, cache);
                let urlCreator = window.URL || window.webkitURL;
                let blobURL = urlCreator.createObjectURL(blob);
                return resolve(blobURL);
            }
        )
    }
    /**
     * Classify Storage errors and generate appropriate reponses
     * 
     * Note: In case of some errors the Insight admin is notified.
     */
    private static generateError(error : any) {
        switch (error.code) {
            case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break;
            case 'storage/object-not-found':
            // File doesn't exist
            break;
            case 'storage/storage/bucket-not-found':
            // No bucket is configured for GCPStorage Storage
            break;
            case 'storage/project-not-found':
                // No project is configured for GCPStorage Storage
                break;
            case 'storage/quota-exceeded':
                // Quota on your GCPStorage Storage bucket has been exceeded. 
                // If you're on the free tier, upgrade to a paid plan. If you're 
                // on a paid plan, reach out to Firebase support.
                break;
            case 'storage/unauthenticated':
            // User doesn't have permission to access the object
            break;
            case 'storage/unauthorized':
                // User is unauthenticated, please authenticate and try again.
                break;
            case 'storage/retry-limit-exceeded':
                // The maximum time limit on an operation (upload, download, delete, etc.) has been 
                // excceded. Try uploading again.
                break;
            case 'storage/invalid-checksum':
                // File on the client does not match the checksum of the file received by the server. 
                // Try uploading again.
                break;
            case 'storage/canceled':
                // User canceled the operation.
                break;
            case 'storage/invalid-event-name':
                // Invalid event name provided. Must be one of [`running`, `progress`, `pause`]
                break;
            case 'storage/invalid-url':
            // User canceled the upload
            break;
            case 'storage/invalid-argument':
                // Invalid URL provided to refFromURL(). Must be of the form: gs://bucket/object or 
                // https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=<TOKEN>
                break;
            case 'storage/no-default-bucket':
                // User canceled the upload
                break;
            case 'storage/cannot-slice-blob':
                // User canceled the upload
                break;
            case 'storage/server-file-wrong-size':
                // User canceled the upload
                break;
            default:
                break;
        }
    }
}