import firebase from 'firebase';
import User from './src/core.analytics.user';
import Billing from './src/core.analytics.billing';
import Storage from './src/core.analytics.storage';
import Messaging from './src/core.analytics.messaging';
import Usage from './src/core.analytics.usage';
/**
 * Core App Analytics Namespace
 */
export namespace analytics {
    /**
     * Initilize core app analytics
     */
    export function init() : void {
        firebase.analytics();
    }
    export class user extends User { }
    export class billing extends Billing { }
    export class storage extends Storage { }
    export class messaging extends Messaging { }
    export class usage extends Usage { }
}

export default analytics;