import {Realtime, Databases} from './core.service.database';
import {User} from './core.service.authentication';
import {Database} from '../../utilities/src/core.util.database';
import {Logger, Event} from '../../support/src/core.support.logger';
// import CryptoJS from 'crypto-js';

export namespace Messaging {
    export interface configuration {}
    /**
     * Interface for Chat Room
     */
    export interface Chat {
        /**
         * active chat room id
         */
        readonly id: string,
        /**
         * Active chat room title
         */
        readonly title: string,
        /**
         * Chat room refference. 
         * The Livechat API creates a unique room
         * for each chat initiator, users who
         * are envited to chat rooms have a `room`
         * refference stored on thier collection
         * of chat rooms.
         */
        readonly room?: string,
        /**
         * Total number of messages not seen
         * by user.
         */
        newMessages: number,
        /**
         * Total number of new members to 
         * chat room not seen by user.
         */
        newMembers: number,
        /**
         * Indicates sync state of the chat 
         * room.
         */
        synced?: boolean
    }
    /**
     * Interface for chat room message
     */
    export interface Message {
        /**
         * Unique message id
         */
        readonly id?: string,
        /**
         * Message text
         */
        readonly text: string,
        /**
         * Indiciates when the message
         * was sent.
         */
        readonly timestamp: number,
        /**
         * Recipient room member id.
         */
        readonly memberID: string,
    }
    /**
     * Interface for chat room member
     */
    export interface Member {
        /**
         * Chat room members id
         */
        readonly id: string,
        /**
         * Chat room id.
         */
        readonly chatID: string,
        /**
         * Indiciates when the member joined
         * the chat room.
         */
        readonly timestamp: number
    }
    /**
     * Interface for messaging session 
     */
    export interface Session {
        /**
         * Collection of active chat sessions for user.
         */
        chats: Array<Chat>
    }
}
/**
 * Core Livechat Services
 */
export class Livechat {
    private static origin: string = "core:service:messaging";
    /**
     * Gets current `Livechat` session data.
     */
    static get session(): Messaging.Session | null 
        {return JSON.parse(`${sessionStorage
            .getItem(Livechat.origin)}`) }
    /**
     * Gets local database instance
     */
    static get database(): Database
        {return new Database(Livechat.origin)}
    /**
     * Get chat room from `Livechat.session`
     * @param chatID active chat room id
     */
    static findSessionRoom(
        chatID: string
    ): Messaging.Chat | null {
        const session = Livechat.session;
        if (!session?.chats) return null;
        for (const room of session?.chats)
            if (room.id === chatID) return room;
        return null;
    }
    /**
     * Get updated chat member record. Returns null if member record was not found.
     * @param chatID Active chat room id
     * @param memberID Chat member id
     */
    static async getMember(
        chatID : string, 
        memberID : string
    ) : Promise<any | null> {
        const database = Livechat.database;
        const table = database.table('members');
        const members = await table.index('chatID')
        .where("chatID",'==', chatID)
        .and('memberID', '==', memberID).get();
        if (members.empty) return null;
        const member = members.getAll().pop();
        const data = member?.data;
        if (data.uid === User?.uid)
            data.isCurrentUser = true;
        return data;
        
        return null;
    }
    /**
     * Gets list of members for active chat.
     * 
     * Note: by default the list is returned in
     * ascending order with a limit of 100 records.
     * @param chatID active chat id
     */
    static async getMembers(
        chatID: string
    ): Promise<Array<Messaging.Member> | undefined> {
        const database = Livechat.database;
        const table = database.table('members');
        const members = await table.index('chatID')
        .where('chatID','==', chatID).get();
        if (members.empty) return undefined;
        let collection: Array<Messaging.Member> = [];
        for (const member of members.getAll())
            collection.push(member.data);
        return collection;
    }
    /**
     * Checks if chat has more then 2 members.
     * @param chatID active chat id
     */
    static async isGroupChat(
        chatID : string
    ) : Promise<Boolean> {
        const members = await Livechat.getMembers(chatID);
        if (members?.length === 3)
            return true;
        return false;
    }
    /**
     * Gets active chat room
     * @param chatID Active chat room id
     */
    static async getChat(
        chatID: string
    ): Promise<Messaging.Chat | undefined> {
        const database = Livechat.database;
        const table = database.table('chat');
        const chat = await table.index('chatID')
        .where('chatID',"==",chatID).get();
        if (chat.empty) return undefined;
        return chat.getAll().pop()?.data as Messaging.Chat;
    }
    /**
     * Gets all active chats for currently logged in user.
     * 
     * Note: if a single chat room is intended to be retreved use
     * `Livechat.getChat(chatID)` instead
     */
    static async getChats() : Promise<Array<Messaging.Chat> | undefined> {
        const database = Livechat.database;
        const table = database.table('chat');
        const chats = await table.get();
        if (chats.empty) return undefined;
        let collection: Array<Messaging.Chat> = [];
        for (const chat of chats.getAll())
            collection.push(chat.data);
        return collection;
    }
    /**
     * Gets chat room index on messaging session
     * @param chatID Active chat room id
     */
    static findChatIndex(
        chatID: string
    ): number | undefined {
        const session = Livechat.session;
        return session?.chats.findIndex(({id}) => id === chatID);
    }
    /**
     * Sets / Updates chat room metadata
     * @param chatID Active chat room id
     * @param metadata New chat metadata
     */
    static async setChat(
        chatID: string,
        metadata: Messaging.Chat
    ): Promise<void> {
        const session = Livechat.session;
        const chatIndex = Livechat.findChatIndex(chatID);
        if (session && chatIndex) {
            session.chats[chatIndex] = metadata;
        }
    }
    /**
     * Get updated chat messages, by default the most recent
     * 100 recieved messages for the current user will be 
     * returned.
     * @param chatID Active chat id
     * @param limit Number of records to return, by
     * default the limjit is set to 100 records.
     */
    static async getMessages(
        chatID: string,
        limit: number = 100
    ) : Promise<Array<Messaging.Message> | undefined> {
        const database = Livechat.database;
        const table = database.table('messages');
        const messages = await table.index('chatID')
        .where('chatID',"==", chatID).get();
        if (messages.empty) return undefined;
        // fetch chat messages and enforce default search limit
        let collection: Array<Messaging.Message> = [];
        for (const message of messages.getAll())
            collection.push(message.data)
        // attemp message metadata decryption
        // ......
        return collection;
    }
    /**
     * Initilize messagning services, and sync current user 
     * messages.
     */
    static init() : Promise<Boolean> {
        Logger.message(Livechat.origin,
            'Initilizing Livechat Services');
        return new Promise(
            async function(resolve, reject) {
                if (!User.current) {
                    return reject(Logger.error({
                        code: "no-authenticated-user",
                        origin: Livechat.origin,
                        description: "No user is logged in"
                    } as Event));
                }
                const database = Livechat.database;
                // initilize messaging api 
                const chat = database.table('chat');
                const members = database.table('members');
                const messages = database.table('messages');
                // create tables (if not already)
                if (!chat.exists) await chat.create("chatID");
                if (!members.exists) await members.create("memberID");
                if (!messages.exists) await messages.create("messageID");
                // create table schema(s)
                await members.index('chatID').create('chatID');
                await members.index('timestamp').create('timestamp');
                await messages.index('chatID').create('chatID');
                await messages.index('text').create('text');
                await messages.index('timestamp').create('timestamp');
                await messages.index('memberID').create('memberID');
                // sync user messages with local instance
                Livechat._sync();
                resolve(true);
            }
        )
    }
    /**
     * Get the last message recieved in chat room.
     * 
     * Returns null if there is no messages in chat room yet.
     * @param chatID Active chat room id
     */
    static async getLastMessageRecieved(
        chatID : string
    ) : Promise<Messaging.Message | null> {
        const database = Livechat.database;
        const table = database.table('messages');
        const messages = (await table.index('chatID')
        .where('chatID',"==", chatID).get())
        .orderBy('timestamp', 'DESC');
        if (messages.empty) return null;
        const message = messages.getAll().pop()?.data;
        return message;
    }
    /**
     * Post message to chat room.
     * 
     * Note max word count should not exceed 200 words.
     * @param chatID Active chat room id
     * @param message Message text to send to chat room
     */
    static async reply(
        chatID : string, 
        message : string
    ) : Promise<void> {
        const userID = User.uid as string;
        // get user refference
        const members = await Livechat.getMembers(chatID);
        if (!members) return;
        // get chat record
        // get chat record
        const chat = await Livechat.getChat(chatID);
        // chat messages refference
        let messages = Realtime
            .ref(`/messaging/${userID}/messages/${chatID}`);
        // check chat designition
        if (chat && chat?.room)
            // get public / group chat refference
            messages = Realtime
            .ref(`${chat.room}/messages/${chatID}`);
        // generate message payload
        let payload: Messaging.Message = {
            text: message,
            timestamp: Date.now(),
            memberID: members[0]?.id
        }
        // send message
        await messages.push(payload).catch(function(error) {
            Logger.error({
                code: "operation-failed",
                origin: Livechat.origin,
                description: error
            } as Event);
            return;
        })
    }
    /**
     * Checks if current user has any unseen or new messages.
     * @param chatID Active chat room id
     */
    static hasNewMessages(
        chatID : string
    ) : boolean {
        const room = Livechat.findSessionRoom(chatID);
        if (room?.newMessages && room.newMessages > 0)
            return true;
        return false;
    }
    /**
     * Get total new / unseen messages arrived for chat room.
     * @param chatID Active chat room id
     */
    static totalNewMessagesInChat(
        chatID : string
    ) : number | null {
        // get messaging session
        const room = Livechat.findSessionRoom(chatID);
        if (room?.newMessages) 
            return room.newMessages;
        else return 0;
    }
    /**
     * Sync users messages with local archive
     */
    private static _sync() {
        // get currently logged in user
        if (User.current)
            // sync user chat records
            Livechat._syncChat();
    }
    /**
     * Sync current users chat records.
     */
    private static _syncChat() {
        Logger.message(Livechat.origin, 
            'Syncing Chats');
        const userID = User.uid;
        // get current chat record instance
        const chat = Realtime
            .ref(`/messaging/${userID}/chat`);
        // attach event listers for chat records
        chat.on('child_added', Livechat.onChatAdded);
        chat.on('child_removed', Livechat.onChatRemoved);
        chat.on('child_changed', Livechat.onChatChanged);
    }
    /**
     * Add event listners for current users chat heads, if listners are attached
     * multiple times the privious listers will be detached first before re-assignment.
     * @param onChatAdded On chat add event handler
     * @param onChatRemoved On chat removed event handler
     * @param onChatChanged On chat changed event handler
     */
    static addChatListners(
        onChatAdded : Function, 
        onChatRemoved : Function, 
        onChatChanged : Function
    ): void {
        Logger.message(Livechat.origin, 
            'Chat Listners Updated By App')
        const userID = User.uid;
        // get current chat record instance
        const chat = Realtime
            .ref(`/messaging/${userID}/chat`);
        // detach service listner
        chat.off('child_added', Livechat.onChatAdded);
        chat.off('child_removed', Livechat.onChatRemoved);
        chat.off('child_changed', Livechat.onChatChanged);
        // re-attach with cutom app handler
        chat.on('child_added', snapshot => 
        Livechat.onChatAdded(snapshot, onChatAdded));
        chat.on('child_removed', snapshot => 
        Livechat.onChatRemoved(snapshot, onChatRemoved));
        chat.on('child_changed', snapshot => 
        Livechat.onChatChanged(snapshot, onChatChanged));
    }
    /**
     * Event haldner for new chat.
     * @param chatsnapshot `Database.DataSnapshot` refference
     * @param handler app event handler
     */
    private static async onChatAdded(
        chatSnapshot : Databases.DataSnapshot, 
        handler: Function | any
    ): Promise<void> {
        Logger.message(Livechat.origin, 
            'New Chat Request Recieved')
        const database = Livechat.database;
        // chat records not synced
        const chatID = chatSnapshot.ref.key as string;
        const chatRoom = chatSnapshot.val()?.room;
        // check if chat record already exists
        const room = await Realtime
        .ref(`${chatRoom}/chat/${chatID}`).once('value');
        const title = room.exists() 
            ? room.val()?.title 
            : chatSnapshot.val()?.title;
        const roomID = room.exists() 
            ? chatRoom
            : chatSnapshot.val()?.room;
        const chat: Messaging.Chat = {
            title: title,
            room: roomID,
            id: chatID,
            newMembers: 0,
            newMessages: 0
        }
        // encrypt sensitive data
        //....
        // store / update chat record
        await database.table("chat").add(chat);
        // sync members for current chat
        await Livechat._syncMembers(chatSnapshot);
        // sync messages for current chat
        await Livechat._syncMessages(chatSnapshot);
        // check if a custom app handler has been attached
        if (typeof handler === "function")
            // inform app of new chat request
            handler(chatID);
    }
    /**
     * Event haldner for chat removal.
     *  @param chatsnapshot `Database.DataSnapshot` refference
     *  @param handler app event handler
     */
    private static async onChatRemoved(
        chatSnapshot : Databases.DataSnapshot, 
        handler : Function | any
    ): Promise<void> {
        Logger.message(Livechat.origin, 'Chat Removed')
        const database = Livechat.database;
        // chat records not synced
        const chatID = chatSnapshot.ref.key as string;
        // get chat record
        const chatRecord = await Livechat.getChat(chatID);
        if (!chatRecord) return;
        // delete local chat record
        (await database.table("chat")
        .where("chatID", '==' ,chatRecord.id).get())
        .batchDelete()
        // get chat members
        const memberRecords = await Livechat.getMembers(chatID);
        // delete all memer records relating to chat
        if (memberRecords !== undefined)
            for (const member of memberRecords)
                (await database.table("chat")
                .index("memberID").where("memberID","==", member.id).get())
                .batchDelete()
        // get all chat messages
        const messageRecords = await Livechat.getMessages(chatID);
        // deleate all chat messages
        if (messageRecords !== undefined)
            for (const message of messageRecords)
                (await database.table("chat").index("messageID")
                .where("messageID","==", message.id as string).get()).batchDelete()
                
        // check if a custom app handler has been attached
        if (typeof handler === "function")
            // inform app of chat deletion
            handler(chatRecord);
    }
    /**
     * Event haldner for chat changes.
     *  @param chatsnapshot `Database.DataSnapshot` refference
     *  @param handler app event handler
     */
    private static onChatChanged(
        chatSnapshot : Databases.DataSnapshot, 
        handler : Function | any
    ): void {}
    /**
     * Sync current users chat memebr records.
     * @param chatRecord `Database.DataSNapshot` refference
     */
    private static async _syncMembers(
        chatRecord : Databases.DataSnapshot
    ): Promise<void> {
        const userID = User.uid;
        const chatID = chatRecord.ref.key as string;
        const chatSession = await Livechat.getChat(chatID);
        if (!chatSession) return;
        // chat record
        const chat: Messaging.Chat = chatRecord.val();
        // chat memebrs refference
        let members = Realtime
        .ref(`/messaging/${userID}/members/${chatID}`);
        // check chat designition
        if (chat?.room)
            // get public / group chat refference
            members = Realtime
            .ref(`${chat.room}/members/${chatID}`);
        // identify last message recived
        const lastMemberAdded = await Livechat
        .getMembers(chatID);
        // get all members added after last recorded member addition
        if (lastMemberAdded)
            // sync members after the last added member
            members = members.orderByChild('timestamp')
            .startAt(lastMemberAdded[0].timestamp+1).ref;
        // sync members 
        await members.once("value", function(snapshot) {
            // new message count
            snapshot.forEach((member) => 
            {Livechat.onMemberAdded(member)})
            // set new messages count
            chatSession.newMembers = snapshot.numChildren();
        }).then(async _ => {
            // store sync state on current session
            chatSession.newMessages = 0;
            chatSession.synced = false;
            await Livechat.setChat(chatID, chatSession);
            Logger.message(Livechat.origin, 
                'Chat Members Synced');
        });
    }
    /**
     * Add event listners to chat members.
     * @param chatID active chat room id
     * @param onMemberAdded Custom handler to envoke on 
     * `Member.Add` event
     * @param onMemberRemoved Custom handler to envoke on 
     * `Member.Removed` event
     * @param onMemberChanged Custom handler to envoke on 
     * `Member.Change` event
     */
    static async addMemberListeners(
        chatID : string, 
        onMemberAdded : Function, 
        onMemberRemoved? : Function, 
        onMemberChanged? : Function
    ): Promise<void> {
        Logger.message(Livechat.origin, 
            'Chat Member Listners Updated By App')
        const userID = User.uid;
        const chat = await Livechat.getChat(chatID);
        // identify chat 
        if (!chat) return;
        // chat memebrs refference
        let members = Realtime
            .ref(`/messaging/${userID}/members/${chatID}`);
        // check chat designition
        if (chat?.room)
            // get public / group chat refference
            members = Realtime
            .ref(`${chat.room}/members/${chatID}`);
        // identify last member added
        const lastMemberAdded = await Livechat
        .getMembers(chatID);
        // get all members added after last recorded member addition
        if (lastMemberAdded)
            // sync members after the last added member
            members = members.orderByChild('timestamp')
            .startAt(lastMemberAdded[0].timestamp+1).ref;
        // detach service lister
        members.off('child_added', Livechat.onMemberAdded);
        members.off('child_removed', Livechat.onMemberRemoved);
        members.off('child_changed', Livechat.onMemberChanged);
        // re-attach with cutom app handler
        members.on('child_added', snapshot => 
        Livechat.onMemberAdded(snapshot, onMemberAdded));
        members.on('child_removed', snapshot => 
        Livechat.onMemberRemoved(snapshot, onMemberRemoved));
        members.on('child_changed', snapshot => 
        Livechat.onMemberChanged(snapshot, onMemberChanged));
    }
    /**
     * Event handler for member addition events.
     * @param snapshot `Database.DataSnapshot1` refference
     * @param handler Custom app handler function
     */
    private static async onMemberAdded(
        snapshot : Databases.DataSnapshot, 
        handler? : Function | any
    ): Promise<void> {
        Logger.message(Livechat.origin, 
            'New Chat Member Added')
        const database = Livechat.database;
        const chatID = snapshot.ref.parent?.key as string;
        const chat = await Livechat.getChat(chatID);
        if (!chat) return;
        const memberID = snapshot.key as string;
        const member: Messaging.Member = {
            chatID: chatID,
            id: memberID,
            timestamp: snapshot.val()?.timestamp
        }
        // add member record
        await database.table('members').add(member);
        // update new member count
        chat.newMembers += 1;
        await Livechat.setChat(chatID, chat);
        // check if a custom app handler has been attached
        if (typeof handler === "function") handler(member);
    }
    /**
     * Event handler for member deletion events.
     * @param snapshot `Database.DataSnapshot1` refference
     * @param handler Custom app handler function
     */
    private static onMemberRemoved(
        snapshot : Databases.DataSnapshot, 
        handler? : Function | any
    ): void {}
    /**
     * Event handler for member change events.
     * @param snapshot `Database.DataSnapshot1` refference
     * @param handler Custom app handler function
     */
    private static onMemberChanged(
        snapshot : Databases.DataSnapshot, 
        handler? : Function | any
    ): void {}
    /**
     * Sync current users chat messages.
     * @param chatRecord `Database.DataSnapshot` refference
     */
    private static async _syncMessages(
        chatRecord : Databases.DataSnapshot
    ): Promise<void> {
        const userID = User.uid;
        const chatID = chatRecord.ref.key as string;
        const roomID = chatRecord.val()?.room;
        const chat = await Livechat.getChat(chatID);
        if (!chat) return;
        // chat memebrs refference
        let messages = Realtime
        .ref(`/messaging/${userID}/messages/${chatID}`);
        // check chat designition
        if (roomID)
            // get public / group chat refference
            messages = Realtime
            .ref(`${roomID}/messages/${chatID}`);
        // identify last message recived
        const lastRecivedMessage = await Livechat
        .getMessages(chatID);
        // get all messages recieved after last recorded message
        if (!lastRecivedMessage)
            // user messages not syned sync all messages from the 
            // past 3 days only
            messages = messages.orderByChild('timestamp')
            .startAt(new Date().getDate()-3).ref;
        else 
            // sync messages after the last recieved message
            messages = messages.orderByChild('timestamp')
            .startAt(lastRecivedMessage[0].timestamp+1).ref;
        // sync messages 
        messages.once("value", function(snapshot) {
            // new message count
            snapshot.forEach((message) => 
            {Livechat.onMessageRecieved(message) })
            // set new messages count
            chat.newMessages = snapshot.numChildren();
        }).then(_ => {
            // sync complete
            chat.synced = true;
            Livechat.setChat(chatID, chat);
            Logger.message(Livechat.origin, 
                'Chat Messages Synced');
        });
    }
    /**
     * Waits for chat to sync, only reolves when 
     * chat is fully synced
     * @param chatID Active chat id
     * @param timeout Timeout limit for sync. By
     * default the timeout is set for 5 seconds.
     */
    static syncInProgress(
        chatID: string,
        timeout: number = 5000
    ) : Promise<Boolean> {
        return new Promise(
            async function(resolve, reject) {
                let timeer = 0;
                let delay = setInterval(async () => {
                    timeer += 50;
                    if (timeer >= timeout) return reject("timeout");
                    // update sync state
                    const chat = await Livechat.getChat(chatID);
                    if (chat?.synced) {
                        clearInterval(delay);
                        return resolve(true);
                    }
                }, 50);
            }
        )
    }
    /**
     * Add Lisnters to chat room messages. 
     * 
     * Note: lisnters will only be attached after the initial 
     * sync is complete,
     * if sync is in progress lister update will be delayed.
     * @param chatID Active chat id
     * @param onMessageRecieved Custom handler to envoke on 
     * `Message.Recieved` event
     * @param onMessageRemoved Custom handler to envoke on 
     * `Message.Recieved` event
     * @param onMessageChanged Custom handler to envoke on 
     * `Message.Changed` event
     */
    static async addMessageListeners(
        chatID : string, 
        onMessageRecieved : Function, 
        onMessageRemoved? : Function, 
        onMessageChanged? : Function
    ): Promise<void> {
        const userID = User.uid;
        // await for chat sync to complete
        await Livechat.syncInProgress(chatID);
        // chat synced update lisnters
        Logger.message(Livechat.origin, 
            'Chat Message Listners Updated By App')
        // get chat record
        const chat = await Livechat.getChat(chatID);
        if (!chat) return;
        // chat messages refference
        let messages = Realtime
        .ref(`/messaging/${userID}/messages/${chatID}`);
        // check chat designition
        if (chat?.room)
            // get public / group chat refference
            messages = Realtime
            .ref(`${chat.room}/messages/${chatID}`);
        // identify last message recived
        const lastRecivedMessage = await Livechat
        .getMessages(chatID);
        // get all messages recieved after last recorded message
        if (!lastRecivedMessage)
            // user messages not syned sync all messages from 
            // the past 3 days only
            messages = messages.orderByChild('timestamp')
            .startAt(new Date().getDate()-3).ref;
        else 
            // sync messages after the last recieved message
            messages = messages.orderByChild('timestamp')
            .startAt(lastRecivedMessage[0].timestamp+1).ref;
        // detach service listners
        messages.on('child_added', snapshot => 
        Livechat.onMessageRecieved(snapshot, onMessageRecieved));
        messages.on('child_removed', snapshot => 
        Livechat.onMessageRemoved(snapshot, onMessageRemoved));
        messages.on('child_changed', snapshot => 
        Livechat.onMessageChanged(snapshot, onMessageChanged));
    }
    /**
     * Event handler for incoming message in chat room.
     * @param snapshot `Database.DataSnapshot` refference
     * @param handler Custom handler to envoke on events
     */
    private static async onMessageRecieved(
        snapshot : Databases.DataSnapshot, 
        handler? : Function | any
    ): Promise<void> {
        Logger.message(Livechat.origin, 
            'New Message Recieved');
        const database = Livechat.database;
        const text = snapshot.val()?.text;
        const memberID = snapshot.val()?.memberID;
        const timestamp = snapshot.val()?.timestamp;
        const chatID = snapshot.ref.parent?.key as string;
        const chat = await Livechat.getChat(chatID);
        if (!chat) return;
        const message: Messaging.Message = {
            id: chatID,
            text: text,
            timestamp: timestamp,
            memberID: memberID
        }
        // store message
        await database.table('messages').add(message);
        // store timestamp for first new message synced for chat
        chat.newMessages += 1;
        Livechat.setChat(chatID, chat);
        // check if a custom app handler has been attached
        if (typeof handler === "function") handler(message);
    }
    /**
     * Event handler for member deletion events.
     * @param snapshot `Database.DataSnapshot1` refference
     * @param handler Custom app handler function
     */
    private static onMessageRemoved(
        snapshot : Databases.DataSnapshot, 
        handler? : Function | any
    ): void {}
    
    /**
     * Event handler for member change events.
     * @param snapshot `Database.DataSnapshot1` refference
     * @param handler Custom app handler function
     */
    private static onMessageChanged(
        snapshot : Databases.DataSnapshot, 
        handler? : Function | any
    ): void {}
}