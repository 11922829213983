import {Query} from '../src/core.util.database';
// worker refference
//const ctx: Worker = self as any;
/**
 * Offline database session interface
 */
 interface Session {
    /**
     * List of registered observers
     */
    observers: Array<Observer>,
    /**
     * List of commited transactions
     */
    transactions: Array<TransactionEvent>
}
/**
 * Database transaction events
 */
type TransactionEvents =
| "create"
| "update"
| "delete";
/**
 * Database observer interface
 */
interface Observer {
    id: string,
    databaseID: string,
    tableID: string,
    documentID?: string,
    timestamp: number
}
/**
 * Database transaction interface
 */
interface TransactionEvent {
    document: any,
    transactionID: TransactionEvents,
    databaseID: string,
    tableID: string,
    timestamp: number,
    syncedObservers: Array<string>
}
/**
 * Check weather obsever has already handled commit
 * @param transaction Active database commit
 * @returns Weather commit is already synced by obsever
 */
const isCommitSyncedByObserver = (
    transaction: TransactionEvent,
    observer: Observer
): Boolean => {
    return transaction.syncedObservers !== undefined &&
    transaction.syncedObservers.find((id) => id === observer.id) !== undefined;
}
/**
 * Identify if the specified scope and type matches the active commit.
 * @param transaction Active database commit
 * @returns Weatehr the commit passes the specified scope
 */
const critrea = (
    observer: Observer,
    transaction: TransactionEvent, 
    scope: "database" | "table" | "document",
    type: TransactionEvents | "all",
    databaseName: string,
    tableName: string,
    documentID: string | undefined
): Boolean => {
    let scopeCritera = false;
    switch (scope) {
        case "database":
            scopeCritera = transaction.databaseID === databaseName;
            break;
        case "table":
            scopeCritera = transaction.databaseID === databaseName
            && transaction.tableID === tableName;
            break;
        default:
            scopeCritera = transaction.databaseID === databaseName 
            && transaction.tableID === tableName
            && transaction.document.id === documentID;
            break;
    }
    return scopeCritera 
    && (type === "all" || transaction.transactionID === type)
    // report commit if occured after observer initilization
    && transaction.timestamp >= observer.timestamp;
}
/**
 * Update commit ledger for all observers.
 * @param activeTransactionIndex Curent transaction index on ledger
 */
const getUpdatedLedger = (
    transactionIndex: number,
    databaseName: string,
    tableName: string,
    observer: Observer,
    session: Session
) => {
    const observers = session.observers || [];
    const transactions = session.transactions || [];
    const activeTransaction = transactions[transactionIndex];
    const totalSyncedObservers = activeTransaction.syncedObservers.length;
    // check if other observers are attached to the current database and table
    let activeObservers: Array<Observer> = [];
    for (const observer of observers)
        if (observer.databaseID === databaseName 
            && observer.tableID === tableName)
            activeObservers.push(observer);
    // The last observer to recieve the event will update the ledger
    if (totalSyncedObservers === activeObservers.length-1)
        transactions.splice(transactionIndex, 1);
    else 
        transactions[transactionIndex] = {...activeTransaction,
            syncedObservers: [...activeTransaction.syncedObservers, observer.id]
        }
    // update ledger
    return {
        transactions: transactions,
        observers: observers
    };
}
/**
 * Entry point for web worker
 * @param event Worker event object
 * @param workerPostMessage TEMP FUNCTION HANDLER REMOVE LATER ON
 */
const workerEntry = async function(event: any, workerPostMessage: Function) {
    const isInitialMount: Boolean = event.data[0];
    const session: Session = event.data[1];
    const observer: Observer = event.data[2];
    const transactions: Array<TransactionEvent> = event.data[1]?.transactions || [];
    const databaseID: string = event.data[3];
    const tableID: string = event.data[4];
    const documentID: string | undefined = event.data[5];
    const type: TransactionEvents | "all" = event.data[6];
    const scope: "database" | "table" | "document" = event.data[7];
    const query = new Query(databaseID, tableID);

    if (isInitialMount) {
        // report all documents as a commit
        console.log('initial observer mount');
        // report all documents as a commit
        for (const document of (await query.getAll()).getAll()) {
            const commit: TransactionEvent = {
                document: {
                    ...document.data, 
                    id: document.id, 
                    cudd_metadata: document.metadata
                },
                tableID: document.metadata.table,
                databaseID: document.metadata.database,
                transactionID: "create",
                timestamp: Date.now(),
                syncedObservers: []
            }
            if (critrea(observer, commit, scope, type, databaseID, tableID, documentID))
                // report as commit
                //ctx.postMessage([commit]);
                workerPostMessage({data: [commit, observer]});
        }
    } 
    //else {
        // cycle through each transaction and identify the database and table
        for (let index = 0; index < transactions.length; index++) {
            const transaction = transactions[index];
            // report commit if custom critrea's are met
            if (critrea(observer, transaction, scope, type, databaseID, tableID, documentID)
            // report commit if not already informed by observer
            && !isCommitSyncedByObserver(transaction, observer)) {
                console.log('transaction observer triggered');
                // inform handler
                // ctx.postMessage([
                //     transaction, 
                //     getUpdatedLedger(index, databaseID, tableID, observer, session)
                // ]);
                workerPostMessage({data: [
                    transaction, observer,
                    getUpdatedLedger(index, databaseID, tableID, observer, session)
                ]});
            }
        }
    //}
}
/**
 * Primary entry point for web worker
 */
//ctx.addEventListener("message", workerEntry);
export default workerEntry;