import Network from '../../utilities/src/core.util.network';

export interface Event {
    origin: string,
    code: string,
    description: string
}
/**
 * Core Support Logger
 */
export class Logger {

    public static init() : void {
        // attach listner to console error logs
        window.onerror = function(message, url, lineNo, columnNo, error) {
            // log error to console
            // auto log cirten errors to apps error catalog for review
        }
    }
    /**
     * Log message to console. If on development enviroment the message will be
     * logged to the console, in production enviroment it will be added
     * to the event stack and pushed to the logstream only if errors are
     * encountered.
     * @param origin origin core library name
     * @param payload message to log to console
     */
    public static message(
        origin : string, 
        payload: string
    ) : void {
        if (!Network.inProductionEnviroment)
            console.info(`😊 [${origin.toLowerCase()}] ${payload}`);
        // log message to core logstream
    }
    /**
     * Log a warning. If on development enviroment the warning will be
     * logged to the console, in production enviroment it will be added
     * to the event stack and pushed to the logstream only if errors are
     * encountered.
     * @param origin service origin name
     * @param payload message to log
     */
    public static warning(
        metadata: Event
    ): void {
        if (!Network.inProductionEnviroment)
            console.warn(`😯 [${metadata.origin.toLowerCase()}] ${metadata.code}\n${metadata.description}`);
        // log message to core logstream
    }
    /**
     * Log error to console. If on development enviroment the error will be
     * logged to the console, in production enviroment the event stack will
     * be collected and pushed to the global logstream for investigation.
     * @param metadata Event Metadata
     */
    public static error(
        metadata: Event
    ) : void {
        // log error for investigation
        if (!Network.inProductionEnviroment)
            console.error(`😫 [${metadata.origin.toLowerCase()}] ${metadata.code}\n${metadata.description}`);
        // log error to core logstream
    }
}