import {Authentication, User} from './src/core.service.authentication';
import {Firestore, Realtime, Databases} from './src/core.service.database';
import {Functions, Function} from './src/core.service.functions';
import {Messaging, Livechat} from './src/core.service.messaging';
import {Newsletters, Newsletter} from './src/core.service.newsletter';
import {NotificationConfiguration, CNotification} from './src/core.service.notification';
import {Storage, GCPStorage} from './src/core.service.storage';

namespace services {
    export interface configuration {
        database?: Databases.configuration,
        notification?: NotificationConfiguration,
        newsletter?: Newsletters.configuration,
        authentication?: Authentication.configuration,
        functions?: Functions.configuration,
        messaging?: Messaging.configuration,
        storage?: Storage.configuration
    }
    export type status = Authentication.Status;
    export function init(config?: services.configuration) {
        Databases.init(config?.database);
        Authentication.init(config?.authentication);
        Storage.init(config?.storage);
        Functions.init(config?.functions);
    }
}
export class firestore extends Firestore { }
export class realtime extends Realtime { }
export class cnotification extends CNotification { }
export class newsletter extends Newsletter { }
export class user extends User { }
export class functions extends Function { }
export class messaging extends Livechat { }
export class storage extends GCPStorage { }

export default services;
