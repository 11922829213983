import Feedback from './src/core.support.feedback';
import Livechat from './src/core.support.livechat';
import {Logger} from './src/core.support.logger';

export namespace support {
    export function init() : void {
        Logger.init()
    }
    export class feedback extends Feedback { }
    export class livechat extends Livechat { }
    export class logger extends Logger { }
}

export default support;