import firebase from 'firebase';
import Network from '../../utilities/src/core.util.network';
import {Logger} from '../../support/src/core.support.logger';

export namespace Functions {
    export type HttpsCallableResult = firebase.functions.HttpsCallableResult;
    export interface configuration {
        emulator: {
            host: string,
            port: number
        }
    }
    /**
     * initilize realtime database services.
     */
    export function init(
        config?: configuration
    ): void {
        // initilize firebase functions
        if (!Network.inProductionEnviroment) {
            const host = config?.emulator.host
            || "localhost";
            const port = config?.emulator.port || 5001
            firebase.functions().useEmulator(host, port);
            Logger.message('core.service.functions', 
            `Development Mode Initilized, Local Instance: http://${host}:${port}`);
        }
    }
}
/**
 * Core Function Services
 */
export class Function {
    /**
     * Call remote firebase cloud function specific to current project.
     * @param name http function name
     * @param args arguments to pass to function
     */
    static async httpsCallable(
        name : string, 
        args : any = null
    ) : Promise<Functions.HttpsCallableResult> {
        // perform insight analytics logging
        // .....
        let rpcFunction = firebase.functions().httpsCallable(name);
        return await rpcFunction(args);
    }
    /**
     * Changes this instance to point to a Cloud Functions emulator running locally. 
     * See https://firebase.google.com/docs/functions/local-emulator
     *  @param origin
     *  The origin of the local emulator, such as "http://localhost:5005".
     */
    static useFunctionsEmulator(
        origin: string
    ) : void {
        // switch to local functions emulator
        firebase.functions().useFunctionsEmulator(origin);
    }
}