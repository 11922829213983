import {Logger} from '../../support/src/core.support.logger';
/**
 * System Notifications Namespace
 */
namespace Notifications {
    /**
     * Notification Utility Scope
     */
    const origin = "core.util.notification";
    /**
     * Check if global notification is supported on this device
     */
    export const isAvailable = "Notification" in window && navigator.serviceWorker;
    /**
     * Current persmission state of the global notifications
     */
    export const permission = window?.Notification?.permission;
    /**
     * Identifies if the permission for the global notfication has been granted.
     */
    export const isPermissionGranted = permission === "granted";
    /**
     * Request Users Permission to send Global Notifications for application.
     * Note: This function should be called before any other GLobal Notification
     * functionality is called.
     */
    export function requestPermission(): Promise<boolean> {
        return new Promise(
            (resolve, reject) => {
                Notification.requestPermission(function(status) {                    
                    Logger.message(origin, `Global Notification Request: ${status}`);
                    if (status === "granted" || status === "default") resolve(true);
                    resolve(false);
                })
            }
        )
    }
    /**
     * Subscribe to the browsers push service and get the subscription object.
     */
    export function requestSubscription(): Promise<PushSubscription> {
        return new Promise(
            function(resolve, reject) {
                if (isAvailable) {
                    navigator.serviceWorker.register("sw.js")
                    .then(function(registration){
                        registration.pushManager.getSubscription()
                        .then(function(subscription){
                            if (subscription === null)
                                reject("No Subscription For Push Service");
                            else 
                                resolve(subscription)
                        })
                    })
                } else reject("Push notificatoins not available on this device.")
            }
        )
    }
    /**
     * Show a system notification to user
     * @param title Title of the notification
     * @param options Customization options for the notification.
     * @example
     * ```javascript
         * // make sure global notification permission is granted
         * if (isNotificationPermissionGranted) {
         *      const options = {
         *          // Notification description
         *          body: 'Here is a notification body!',
         *          // Attaches an image to make the notification more 
         *          // visually appealing, but also more relevant to the user
         *          icon: 'images/example.png',
         *          // specifies a vibration pattern for a phone receiving the notification. 
         *          // In this example, a phone would vibrate for 100 milliseconds, pause 
         *          // for 50 milliseconds, and then vibrate again for 100 milliseconds.
         *          vibrate: [100, 50, 100],
         *          // option attaches custom data to the notification, so that the service 
         *          // worker can retrieve it when the user interacts with the notification. 
         *          // For instance, adding a unique "id" or "key" option to the data allows us 
         *          // to determine which notification was clicked when the service worker handles 
         *          // the click event.
         *          data: {
         *              dateOfArrival: Date.now(),
         *              primaryKey: 1
         *          },
         *          // To create a notification with a set of custom actions, we add an actions array 
         *          // inside the notification options object. This array contains a set of objects that 
         *          // define the action buttons to show to the user. Actions can have an identifier string, 
         *          // a title containing text to be shown to the user, and an icon containing the location 
         *          // of an image to be displayed next to the action.
         *          actions: [
         *              {action: 'explore', title: 'Explore this new world',
         *                  icon: 'images/checkmark.png'},
         *              {action: 'close', title: 'Close notification',
         *                  icon: 'images/xmark.png'},
         *          ]
         *      }
         *      showNotification("Hello World1", options)
         * }
         * ...
         * ...
         * ```
     */
    export function showNotification(
        title: string,
        options?: NotificationOptions
    ): Promise<void> {
        return new Promise(
            (resolve, reject) => {
                if (isPermissionGranted) {
                    navigator.serviceWorker.getRegistration()
                    .then(function(registration) {
                        registration?.showNotification(title, options)
                        .then(_ => resolve(), _ => reject())
                    }, _ => reject())
                } else reject("Permission not granted for global notifications, call requestPermission().")
            }
        )
    }
    /**
     * Global Notifications Event Initilizer.
     * This function should be called within the `serviceWorker.js`.
     */
    export function initGlobalNotifications(
        onNotificationClose: (event: Event) => {},
        onNotificationClick: (event: Event) => {},
        onPushEvent: (event: Event) => {}
    ) {
        // add notification closed event lisnter
        self.addEventListener('notificationclose', function(event) {
            // perform notification analytics
            // .....
            // pass invocation to custom handler
            onNotificationClose(event);
            // log event
            Logger.message(origin, "Notification Closed Event");
        })
        // add notification click event
        self.addEventListener('notificationclick', function(event) {
            // perform notification analytics
            // ....
            // pass invocation to custom handler
            onNotificationClick(event);
            // log event
            Logger.message(origin, "Notification Click Event");
        });
        // add push event for incoming notifications
        self.addEventListener('push', function(event) {
            // perform notification analytics
            // ...
            // pass invocation to custom handler
            onPushEvent(event);
            // log event
            Logger.message(origin, "Incoming Push Event Recieved");
        });
    }
}

export default Notifications;