/**
 * Date Utilities
 */
namespace Dates {
    /**
     * Calendar Shorthand Representations of Months
     */
    export enum ShortMonths {
        January = "Jan",
        February = "Feb",
        March = "Mar",
        April = "Apr",
        May = "May",
        June = "Jun",
        July = "Jul",
        August = "Aug",
        September = "Sep",
        October = "Oct",
        November = "Nov",
        December = "Dec"
    };
    /**
     * Calendar Representations of Months
     */
    export enum Months {
        January = "January",
        February = "February",
        March = "March",
        April = "April",
        May = "May",
        June = "June",
        July = "July",
        August = "August",
        September = "September",
        October = "October",
        November = "November",
        December = "December"
    };
    /**
     * Calendar Shorthand Representations of Months
     */
    export enum ShortDays {
        Monday = "Mon",
        Tuesday = "Tue",
        Wednesday = "Wed",
        Thursday = "Thu",
        Friday = "Fri",
        Saturday = "Sat",
        Sunday = "Sun"
    };
    /**
     * Calendar Representations of Months
     */
    export enum Days {
        Monday = "Monday",
        Tuesday = "Tuesday",
        Wednesday = "Wednesday",
        Thursday = "Thursday",
        Friday = "Friday",
        Saturday = "Saturday",
        Sunday = "Sunday"
    };
    
    /**
     * Returns a list of days in a week.
     * @param format Format to return the list in, `full` will
     * return the full calendar name, and `short` will return
     * a the shorter 3 character representaion of the days.
     * @param shortHand Return shorthand format
     */
    export function getCalendarDays(
        shortHand: boolean = false
    ): Array<string> {
        if (!shortHand)
            return [Days.Monday,Days.Tuesday,Days.Wednesday,Days.Thursday,
            Days.Friday,Days.Saturday,Days.Sunday];
        return [ShortDays.Monday,ShortDays.Tuesday,ShortDays.Wednesday,ShortDays.Thursday,
            ShortDays.Friday,ShortDays.Saturday,ShortDays.Sunday];
    }
    /**
     * Returns a list of months in a year.
     * @param shortHand Return shorthand format
     */
    export function getCalendarMonths(
        shortHand: boolean = false
    ): Array<string> {
        if (!shortHand)
            return [Months.January,Months.February,Months.March,Months.April,
            Months.May,Months.June,Months.July,Months.August,Months.September,
            Months.October,Months.November,Months.December];
        return [ShortMonths.January,ShortMonths.February,
        ShortMonths.March,ShortMonths.April,
        ShortMonths.May,ShortMonths.June,ShortMonths.July,
        ShortMonths.August,ShortMonths.September,
        ShortMonths.October,ShortMonths.November,ShortMonths.December];
    }
    /**
     * Core Util Date Class
     */
    export class CDate {
        // current date reference
        private date: Date;
        // initilize initial date value
        constructor(
            date: Date | string | number = new Date()
        ) {
            this.date = new Date(date);;
        }
        /**
         * Get JavaScript Date object of current date value.
         */
        get dateObject(): Date {return new Date(this.date)}
        /**
         * Convert date to specific format.
         * @param format Date format to return. By default `yyyy-mm-dd` will be returned.
         * @param delimiter Separator delimiter to use. By default `-` is used.
         * @param useUTC Specifies to use UTC (Universal Time Coordinated) standard, by default
         * UTC will not be used.
         */
        format(
            format: "dd-mm-yyyy" | "mm-dd-yyyy" | "yyyy-mm-dd" = "yyyy-mm-dd",
            delimiter: "space" | "-" | "/" | "." = "-",
            useUTC: boolean = false
        ): string {
            // get date object
            const year = this.getYear(useUTC);
            const month = this.getMonth("mm", useUTC);
            const day = this.getDate("dd", useUTC);
            switch (format) {
                case "dd-mm-yyyy":
                    return `${day}${delimiter}${month}${delimiter}${year}`;
                case "mm-dd-yyyy":
                    return `${month}${delimiter}${day}${delimiter}${year}`;
                default:
                    return `${year}${delimiter}${month}${delimiter}${day}`;
            }
        }
        /**
         * Get calendar month value.
         * @param month Numeric month value
         * @param shortHand Return shorthand format
         */
        getCalendarDay(
            shortHand: boolean = false
        ): string {
            switch (this.getDay()) {
                case 1:
                    return !shortHand ? 
                    Days.Monday : ShortDays.Monday;
                case 2:
                    return !shortHand ? 
                    Days.Tuesday : ShortDays.Tuesday;
                case 3:
                    return !shortHand ? 
                    Days.Wednesday : ShortDays.Wednesday;
                case 4:
                    return !shortHand ? 
                    Days.Thursday : ShortDays.Thursday;
                case 5:
                    return !shortHand ? 
                    Days.Friday : ShortDays.Friday;
                case 6:
                    return !shortHand ? 
                    Days.Saturday : ShortDays.Saturday;
                default:
                    return !shortHand ? 
                    Days.Sunday : ShortDays.Sunday;
            }         
        }
        /**
         * Extract day of the week. To get the normalized calendar day use
         * `getCalendarDay()`.
         * @param format Day format to return, `dd` will return numeric day value
         * and `calender` will return a user friendly day value
         * @param useUTC Specifies to use UTC (Universal Time Coordinated) standard, by default
         * UTC will not be used.
         */
        getDay(
            useUTC: boolean = false
        ): number {
            const day = useUTC ? this.dateObject.getUTCDay()+1 : this.dateObject.getDay();
            return day;
        }
        /**
         * Extract day from date value
         * @param format Day format to return, `dd` will return string day value,
         * and `numeric` will return the numeric date value
         * @param useUTC Specifies to use UTC (Universal Time Coordinated) standard, by default
         * UTC will not be used.
         */
        getDate(
            format: "numeric" | "dd" = "numeric",
            useUTC: boolean = false
        ): string | number {
            const day = useUTC ? this.dateObject.getUTCDate() : this.dateObject.getDate();
            switch (format) {
                case "numeric":
                    return day;
                default:
                    return day < 10 ? `0${day}` : `${day}`;
            }
        }
        /**
         * Returns the total number of days in a given month of a year.
         */
        get getDaysInMonth(): number {
            // extract month and year
            const month = this.getMonth("mm");
            const year = this.getYear();
            // validate the maximum possiable days in any given month
            const MAX_DAYS = 31;
            const MIN_DAYS = 28;
            for (let day = MAX_DAYS; day >= MIN_DAYS ; day--) {
                const date = new Date(`${month}/${day}/${year}`).getDate();
                if (!isNaN(date) && !(date < MIN_DAYS))
                    return date;
            }
            return MAX_DAYS;
        }
        /**
         * Identify days difference between two date bounds. The lower bound date
         * will be initial date value provided.
         * @param upperBound Upper bound date
         * @param inclusive Indicates that the current day, month or year be 
         * considered during calculation, by default this is false.
         * @param useUTC Specifies to use UTC (Universal Time Coordinated) standard, by default
         * UTC will not be used.
         */
        getDayDifference(
            upperBound: Date | number | string,
            inclusive: boolean = false,
            useUTC: boolean = false
        ): number {
            // upperbound date reference
            const upperBoundRef = new CDate(upperBound);
            // year bounds
            const upperBoundYear = upperBoundRef.getYear(useUTC);
            const lowerBoundYear = this.getYear(useUTC);
            // month bounds
            const upperBoundMonth = upperBoundRef.getMonth("numeric", useUTC) as number;
            const lowerBoundMonth = this.getMonth("numeric", useUTC) as number;
            // day bounds
            const upperBoundDay = upperBoundRef.getDate("numeric", useUTC) as number;
            const lowerBoundDay = this.getDate("numeric", useUTC) as number;
            // calculate difference in days within the same month and year bound
            if (lowerBoundYear === upperBoundYear
                && lowerBoundMonth === upperBoundMonth)
                return (upperBoundDay - lowerBoundDay)+(inclusive ? 1 : 0);
            /**
             * calculate difference in days for larger months
             * and year bounds
             */
            let days = inclusive ? 1 : 0;
            // calculate difference between the same year
            if (lowerBoundYear === upperBoundYear) {
                for (let month = lowerBoundMonth; month <= upperBoundMonth; month++) {
                    let totalDaysInMonth = 
                    new CDate(`${month}/1/${upperBoundYear}`).getDaysInMonth;
                    if (month === lowerBoundMonth) totalDaysInMonth -= lowerBoundDay;
                    if (month === upperBoundMonth) totalDaysInMonth = upperBoundDay;
                    days += totalDaysInMonth;
                }
            } else {    
                /**
                 * Cycle through each inclusive year and month
                 * range of the two date bounds
                 */
                for (let year = lowerBoundYear; year <= upperBoundYear; year++) {
                    if (lowerBoundYear === year) {
                        for (let month = lowerBoundMonth; month <= 12; month++) {
                            let totalDaysInMonth = 
                            new CDate(`${month}/1/${year}`).getDaysInMonth;
                            if (month === lowerBoundMonth) totalDaysInMonth -= lowerBoundDay;
                            days += totalDaysInMonth;
                        }
                    } else if (upperBoundYear === year) {
                        for (let month = 1; month <= upperBoundMonth; month++) {
                            let totalDaysInMonth = 
                            new CDate(`${month}/1/${year}`).getDaysInMonth;
                            if (month === upperBoundMonth) totalDaysInMonth = upperBoundDay;
                            days += totalDaysInMonth;
                        }
                    } else {
                        days += this.isLeapYear(year) ? 366 : 365;
                    }
                }
            }
            return days;
        }
        /**
         * Increment current day value by specified days. This will also 
         * update the current date refference with the new incremented value.
         * @param by Days count to increment by.
         */
        incrementDay(
            by: number
        ): void {
            let day = this.getDate() as number;
            let month = this.getMonth()  as number;
            let year = this.getYear();
            // identify new date value
            const totalDays = day+by;
            // cycle through proceeding days
            for (let currentDay = day; currentDay < totalDays; currentDay++) {
                if (this.getDaysInMonth === day) {
                    // month end reached
                    year = month === 12 ? year+1 : year;
                    month = month === 12 ? 1 : month+1;
                    day = 1;
                } else day = day+1;
                // update date refference
                this.date = new Date(`${month}/${day}/${year}`);
            }
        }
        /**
         * Decrement current day value by specified days. This will also 
         * update the current date refference with the new decremented value.
         * @param by Days count to increment by.
         */
        decrementDay(
            by: number
        ): void {
            let day = this.getDate() as number;
            let month = this.getMonth()  as number;
            let year = this.getYear();
            // identify new date value
            const totalDays = day-by;
            // cycle through proceeding days
            for (let currentDay = day; currentDay > totalDays; currentDay--) {
                day = day-1;
                // identify month end critrea
                if (day === 0) {
                    // month end reached
                    // for decrementation 0 identifies a month end
                    year = month === 1 ? year-1 : year;
                    month = month === 1 ? 12 : month-1;
                    // get total days in previous month
                    day = new CDate(`${month}/1/${year}`).getDaysInMonth;
                }
                // update date refference
                this.date = new Date(`${month}/${day}/${year}`);
            }
        }
        /**
         * Extract month from a specific date.
         * @param format Month format to return, `numeric` will return the 
         * integer index of the month, `mm` will return a string month value
         * and `calender` will return a user friendly month value. By Default
         * `numeric` will be returned.
         * @param useUTC Specifies to use UTC (Universal Time Coordinated) standard, by default
         * UTC will not be used.
         */
        getMonth(
            format: "numeric" | "mm" = "numeric",
            useUTC: boolean = false
        ): string | number {
            let month = useUTC ? this.dateObject.getUTCMonth()+1 : this.dateObject.getMonth()+1;
            switch (format) {
                case "mm":
                    return month < 10 ? `0${month}` : `${month}`;
                default:
                    return month;
            }
        }
        /**
         * Get calendar month value
         * @param month Numeric month value
         * @param shortHand Return shorthand format
         */
        getCalendarMonth(
            shortHand: boolean = false
        ): string {
            switch (this.getMonth()) {
                case 1:
                    return !shortHand ? 
                    Months.January : ShortMonths.January;
                case 2:
                    return !shortHand ? 
                    Months.February : ShortMonths.February;
                case 3:
                    return !shortHand ? 
                    Months.March : ShortMonths.March;
                case 4:
                    return !shortHand ? 
                    Months.April : ShortMonths.April;
                case 5:
                    return !shortHand ? 
                    Months.May : ShortMonths.May;
                case 6:
                    return !shortHand ? 
                    Months.June : ShortMonths.June;
                case 7:
                    return !shortHand ? 
                    Months.July : ShortMonths.July;
                case 8:
                    return !shortHand ? 
                    Months.August : ShortMonths.August;
                case 9:
                    return !shortHand ? 
                    Months.September : ShortMonths.September;
                case 10:
                    return !shortHand ? 
                    Months.October : ShortMonths.October;
                case 11:
                    return !shortHand ? 
                    Months.November : ShortMonths.November;
                default:
                    return !shortHand ? 
                    Months.December : ShortMonths.December;
            }         
        }
        /**
         * Identify month difference between two date bounds.
         * @param upperBound Upper bound date
         * @param inclusive Indicates that the current day, month or year be 
         * considered during calculation, by default this is false.
         * @param useUTC Specifies to use UTC (Universal Time Coordinated) standard, by default
         * UTC will not be used.
         */
        getMonthDifference(
            upperBound: Date | number | string,
            inclusive: boolean = false,
            useUTC: boolean = false
        ): number {
            // upperbound CDate ref
            const upperBoundRef = new CDate(upperBound);
            // year bounds
            const upperBoundYear = upperBoundRef.getYear(useUTC);
            const lowerBoundYear = this.getYear(useUTC);
            // month bounds
            const upperBoundMonth = upperBoundRef.getMonth("numeric", useUTC) as number;
            const lowerBoundMonth = this.getMonth("numeric", useUTC) as number;
            // calculate difference in months
            if (lowerBoundYear === upperBoundYear)
                return upperBoundMonth - lowerBoundMonth;
            /**
             * calculate difference in months for larger
             * upper bounds
             */
            let months = inclusive ? 1 : 0;
            /**
             * cycle through each inclusive year in the range
             * of the two date bounds
             */
            for (let year = lowerBoundYear; year <= upperBoundYear; year++) {
                if (lowerBoundYear === year) {
                    months += (12 - lowerBoundMonth);
                } else if (upperBoundYear === year) {
                    months += upperBoundMonth;
                } else {
                    months += 12;
                }
            }
            return months;
        }
        /**
         * Increment current month value by specified months. This will also 
         * update the current date refference with the new incremented value.
         * @param by Months count to increment by.
         */
        incrementMonth(
            by: number
        ): void {
            let day = this.getDate() as number;
            let month = this.getMonth()  as number;
            let year = this.getYear();
            // identify new date value
            const totalMonths = month+by;
            // cycle through proceeding months
            for (let currentMonth = month; currentMonth < totalMonths; currentMonth++) {
                year = month === 12 ? year+1 : year;
                month = month === 12 ? 1 : month+1;
                // update date refference
                this.date = new Date(`${month}/${day}/${year}`);
            }
        }
        /**
         * Decrement current month value by specified months. This will also 
         * update the current date refference with the new decremented value.
         * @param by Months count to increment by.
         */
        decrementMonth(
            by: number
        ): void {
            let day = this.getDate() as number;
            let month = this.getMonth()  as number;
            let year = this.getYear();
            // identify new date value
            const totalMonths = month-by;
            // cycle through proceeding days
            for (let currentMonth = month; currentMonth > totalMonths; currentMonth--) {
                year = month === 1 ? year-1 : year;
                month = month === 1 ? 12 : month-1;
                // update date refference
                this.date = new Date(`${month}/${day}/${year}`);
            }
        }
        /**
         * Check if the provided year is a leap year.
         * If no year is provided the current year will be checked.
         * @param year Year to check
         */
        isLeapYear(
            year?: number
        ): boolean {
            if (!year) year = this.getYear();
            return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
        }
        /**
         * Extract year from a specific date
         * @param useUTC Specifies to use UTC (Universal Time Coordinated) standard
         */
        getYear(
            useUTC: boolean = false
        ): number {
            return useUTC ? this.dateObject.getUTCFullYear() : this.dateObject.getFullYear();
        }
        /**
         * Identify year difference between two date bounds.
         * @param upperBound Upper bound date
         * @param inclusive Indicates that the current day, month or year be 
         * considered during calculation, by default this is false.
         * @param useUTC Specifies to use UTC (Universal Time Coordinated) standard, by default
         * UTC will not be used.
         */
        getYearDifference(
            upperBound: Date | number | string,
            inclusive: boolean = false,
            useUTC: boolean = false
        ): number {
            // upperbound CDate ref
            const upperBoundRef = new CDate(upperBound);
            // year bounds
            const upperBoundYear = upperBoundRef.getYear(useUTC);
            const lowerBoundYear = this.getYear(useUTC);
            // calculate difference in years
            return (upperBoundYear - lowerBoundYear) + (inclusive ? 1 : 0);
        }
        /**
         * Increment current year value by specified years. This will also 
         * update the current date refference with the new incremented value.
         * @param by Years count to increment by.
         */
        incrementYear(
            by: number
        ): void {
            let day = this.getDate() as number;
            let month = this.getMonth()  as number;
            let year = this.getYear();
            // update date refference
            this.date = new Date(`${month}/${day}/${year + by}`);
        }
        /**
         * Decrement current year value by specified years. This will also 
         * update the current date refference with the new decremented value.
         * @param by Years count to increment by.
         */
        decrementYear(
            by: number
        ): void {
            let day = this.getDate() as number;
            let month = this.getMonth()  as number;
            let year = this.getYear();
            // update date refference
            this.date = new Date(`${month}/${day}/${year - by}`);
        }
    }
}

export default Dates;