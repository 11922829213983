import {Logger} from '../../support/src/core.support.logger';
/**
 * Network Configuration Interface
 */
// interface configuration {}
/**
 * Core Network Services
 */
class Network {
    private static get origin(): string {return "core.services.network";}
    /**
     * Initilize network services
     */
    static init() : void {
        if (!Network.inProductionEnviroment) {
            Logger.message(Network.origin, 'Services Running In Development Mode, '+
            'visit https://www.enigmasoftworks.com/core/dev for more information');
        } else {
            // enable production mode
            Logger.message(Network.origin, 'Services initilized, visit '+
            'https://www.enigmasoftworks.com/core for more information');
        }       
    }
    /**
     * Enabled offline functionslities, if supported.
     */
    static enableOfflineUsage() : boolean {
        // enable 
        return true;
    }
    /**
     * Checks and returns devices online status. To attach listner on network changes
     * use Network.onStateChange.
     */
    static get isOnline() : boolean 
    { return window.navigator.onLine }
    /**
     * Check weather the app is running on the production enviroment.
     */
    static get inProductionEnviroment() : boolean 
    { return window.location.hostname !== "localhost" }
    /**
     * Attaches a listner that is envoked when devices online status changes. The
     * handler is envoked passing a boolean which indicates devices online state.
     */
    static onStateChange(
        appHandler: Function
    ) : void {
        const navigator : any = window.navigator;
        // attach lister on device network changes
        const connection = navigator.connection 
        || navigator.mozConnection 
        || navigator.webkitConnection;
        connection.addEventListener('change', 
        function() { 
            appHandler(Network.isOnline);
            // log event
            Logger.message(Network.origin, 
            `Network State Changed. User is ${Network.isOnline} 
            with a ${connection.type} connection.`)
        });
    }
}

export default Network;