import firebase from 'firebase';
import { Firestore } from './core.service.database';
import { GCPStorage, Storage } from './core.service.storage';
import { Logger, Event } from '../../support/src/core.support.logger';
import Network from '../../utilities/src/core.util.network';
import { Database } from '../../utilities/src/core.util.database';

var CryptoJS = require("crypto-js");
/**
 * Core Authentication Namespace
 */
export namespace Authentication {
    export const origin = "core.services.authentication";
    export interface configuration {
        emulator?: string
    }
    /**
     * initilize realtime database services.
     */
    export function init(
        config?: configuration
    ): void {
        // initilize firebase functions
        if (!Network.inProductionEnviroment) {
            const emulator = config?.emulator
                || "http://localhost:9099";
            firebase.auth().useEmulator(emulator);
            Logger.message(Authentication.origin,
                `Development Mode Initilized, Local Instance: ${emulator}`);
        }
    }
    /**
     * User Account Status
     */
    export enum Status {
        /**
         * User account registration is in-complete,
         * and requires further actions.
         */
        Incomplete = "in-complete",
        /**
         * User account is fully registered, all
         * requirments have been met.
         */
        Complete = "complete",
        /**
         * User accounts is under warning of recent
         * breach of applications terms of service.
         * Accounts with a `warning` status will be 
         * monitored for further illicit activities.
         */
        Warning = 'warning',
        /**
         * User account has been banned for a fixed
         * duration of time due to illicit activity,
         * the ban will be lifted after ban duration
         * is over, once a ban is implimented on a 
         * account the user will be `suspended` if 
         * another ban is set.
         */
        Banned = "banned",
        /**
         * User account has been permanently suspended
         * due to multiple illicit activies or a 
         * pre-existing `banned` state on account.
         * Accounts with `suspended` state will
         * flag the email address and wont allow 
         * any logins or re-creation of accounts until
         * the account is set to `removed`.
         */
        Suspended = 'suspended',
        /**
         * User account has been removed from applications
         * list of active accounts, this is either due to
         * a pre-existing `suspended` state, or account
         * being in-active for a long period of time.
         * Accounts marked for removal will be premanently
         * disolved and the email address will be released
         * for account re-creations.
         */
        Removed = 'removed'
    }
    /**
     * Cached User Instance
     */
    export interface Cache {
        /**
         * users public display name.
         */
        readonly displayName: string | null,
        /**
         * user account email address.
         */
        readonly email: string | null,
        /**
         * True if users email address has 
         * been verified, false otherwise.
         */
        readonly isEmailVerified: boolean,
        /**
         * users configured phone number
         */
        readonly phoneNumber?: string | null,
        /**
         * users public profile image url
         */
        readonly photoURL: string | null,
        /**
         * id of current trusted device
         */
        readonly deviceID: string,
        /**
         * custom app configuration for current user.
         * @example
         * ```javascript
         * var savedTheme = auth.cache.appConfig.theme;
         * ```
         */
        appConfig?: any | null,
        /**
         * Custom user metadata.
         */
        profile?: any | null,
        /**
         * User generated user metadata
         */
        readonly metadata: firebase.auth.UserMetadata,
        /**
         * Users selected external auth provider data.
         * Only  set when user selects other sign up methods
         * then email and password.
         */
        readonly providerData?: (firebase.UserInfo | null)[]
    }
    /**
     * Interface for authentication session
     */
    export interface Session {
        /**
         * When true, all auth events triggered 
         * will not be notified to the application handler.
         */
        revokeAuthEvents: boolean,
        /**
         * When true, current device will be added to
         * users list of trusted devices.
         */
        addAsTrustedDevice: boolean
    }
    /**
     * Interface for trusted device
     */
    export interface Device {
        /**
         * Auto generated device id.
         */
        readonly deviceID: string,
        /**
         * Number of logical processor cores on device.
         */
        readonly hardwareConcurrency: number,
        /**
         * Device default language
         */
        readonly language: string,
        /**
         * Device oprtating system
         */
        readonly platform: string,
        /**
         * User browser agent
         */
        readonly userAgent: string
    }
}
/**
 * Authentication Offline Services
 */
class Offline {
    /**
     * Check if offline use is available
     */
    static get isOffline(): boolean { return !Network.isOnline && Offline.isTrustedDevice }
    /**
     * Get cached auth instance
     * Note: The cached auth instance automatically 
     * updates upon every successful 
     * login, thus current data may not be up-to-date.
     */
    static get cache(): Authentication.Cache | null { return JSON.parse(`${localStorage.getItem(Authentication.origin)}`) }
    /**
     * Get session auth instance
     * Note: The session auth instance is automatically 
     * cleared after every logout / login
     */
    static get session(): Authentication.Session | null { return JSON.parse(`${sessionStorage.getItem(Authentication.origin)}`) }
    /**
     * User local database instance
     */
    static get database(): Database { return new Database(Authentication.origin) }
    /**
     * Check if current device is trusted by current user
     */
    static get isTrustedDevice(): boolean { return Offline.cache?.deviceID !== undefined }
    /**
     * Sets session auth instance
     * @param session Updated session
     */
    static setSession(
        session: Authentication.Session
    ): void {
        sessionStorage.setItem(Authentication.origin,
            JSON.stringify(session));
    }
    /**
     * Remove User session data
     */
    static clearSession(): void { sessionStorage.clear() }
    /**
 * Sets local auth instance
 */
    static setCache(
        cache: Cache
    ): void {
        localStorage.setItem(Authentication.origin,
            JSON.stringify(cache));
    }
    /**
     * Remove local auth instance
     */
    static clearCache(): void {
        localStorage.removeItem(Authentication.origin);
        // end current user session
        User.signOut();
    }
    /**
     * Initilize offline authentication services
     * @param user active user auth instance
     */
    static async init(
        user: firebase.User
    ): Promise<void> {
        // get database object
        const database = Offline.database;
        //initilize messaging api
        const table = database.table('user');
        //create table
        if (!(await table.exists())) {
            await table.create("email", false, false);
            // create indexs for optimized searching
            await table.index('email').create('email', true);
            await table.index('deviceID').create('deviceID');
            await table.index('displayName').create('displayName');
        }
        // check if user identified device as trusted
        if (Offline.session?.addAsTrustedDevice) {
            // sync user on device
            table.add({
                // create device id
                deviceID: CryptoJS.AES
                    .encrypt(User?.email
                        || 'anonymous' + Date.now(),
                        User.uid).toString(),
                // cache user metadata
                displayName: user.displayName,
                email: user.email,
                isEmailVerified: user.emailVerified,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                metadata: user.metadata,
                providerData: user.providerData,
                // cache user profile
                profile: await User.getProfile()
            });
            // add device to users trusted list
            await Offline.addToTrustedDevices();
        }
        if (Offline.isTrustedDevice)
            // attach listner for device trust changes
            Offline.onTrustedDeviceChange();
        // begin new user session
        // Offline.clearSession();
    }
    /**
     * Add current device to user list of trusted devices.
     */
    static async addToTrustedDevices(): Promise<void> {
        // create device id
        const deviceID = CryptoJS.AES.encrypt(User?.email
            || 'anonymous' + Date.now(), User.uid).toString();
        // save device on user profile
        // get device info
        const deviceInfo: Authentication.Device = {
            // generate unique device id
            deviceID: deviceID,
            hardwareConcurrency: navigator.hardwareConcurrency,
            language: navigator.language,
            platform: navigator.platform,
            userAgent: navigator.userAgent
        }
        // save to users trusted device list
        await Firestore
            .collection('users').doc(User.uid)
            .collection('trustedDevices')
            .doc(deviceInfo.deviceID).set(deviceInfo);
    }
    /**
     * Add Lister to users trusted devices list.
     * Note: If device trust is removed the current users 
     * session will be closed.
     */
    static onTrustedDeviceChange(): void {
        const trustedDeviceListner = Firestore
            .collection('users').doc(User.uid)
            .collection('trustedDevices').onSnapshot(snap => {
                const changes = snap.docChanges();
                // check if device trust was changed
                let isTrusted: boolean = false;
                for (const device of changes) {
                    if (device.type === 'removed' &&
                        device.doc.id === Offline.cache?.deviceID) {
                        // device trust removed
                        if (trustedDeviceListner) trustedDeviceListner();
                        // remove local user cache and end currrent session
                        Offline.clearCache();
                        return;
                    }
                    if (device.doc.id === Offline.cache?.deviceID) {
                        isTrusted = true;
                        break;
                    }
                }
                if (!isTrusted) {
                    // device trust removed
                    if (trustedDeviceListner) trustedDeviceListner();
                    // remove local user cache and end currrent session
                    Offline.clearCache();
                    return;
                }
            });
    }
}
/**
 * Core Authentication Services.
 */
export class User {
    /**
     * Gets the current authenticated user 
     * (if signhed in).
     * Note: current only gets a users auth data 
     * not Custome created details, 
     * use currentUserWithData to get the users 
     * custome details as well.
     */
    static get current(): firebase.User | null { return firebase.auth().currentUser }
    /**
     * Check if any user is logged in
     */
    static get isLoggedIn(): boolean { return User.current?.uid !== undefined }
    /**
     * Get currently logged in users uid
     */
    static get uid(): string | undefined { return User.current?.uid }
    /**
     * Returns a JSON Web Token (JWT) used to 
     * identify the user to a Firebase service.
     * Returns the current token if it has not 
     * expired. Otherwise, this will 
     * refresh the token and return a new one.
     */
    static get IdToken(): Promise<string> | undefined { return User.current?.getIdToken() }
    /**
     * Get currently logged in users diaplyName
     */
    static get displayName(): string | null | undefined { return User.current?.displayName }
    /**
     * Get currently logged in users email
     */
    static get email(): string | null | undefined { return User.current?.email }
    /**
     * Check if users email has been verified
     */
    static get isEmailVerified(): boolean | undefined { return User.current?.emailVerified }
    /**
     * The phone number normalized based on the 
     * E.164 standard (e.g. +16505550101) 
     * for the current user. This is null if 
     * the user has no phone credential 
     * linked to the account.
     */
    static get phoneNumber(): string | null | undefined { return User.current?.phoneNumber }
    /**
     * Offline authentication services.
     */
    static get offline(): Offline { return new Offline() }
    /**
     * Intilize User Authentication Services.
     * @param user Active Firebase user
     * @param onChange App auth event handler
     */
    private static init(
        user: firebase.User,
        onChange: Function
    ): Promise<void> {
        Logger.message(Authentication.origin,
            'Initilizing User Services');
        return new Promise(
            async function (resolve, reject) {
                // attach listenr to users profile metadata
                User.onProfileChange(onChange);
                // sync user on device
                await Offline.init(user);
                // inform completion
                resolve();
            }
        )
    }
    /**
     * Disables auth change listener.
     * 
     * Note: User changes will not be notified until
     * User.envokeAuthEventss is called.
     */
    static revokeAuthEvents(): void {
        const session = Offline.session
            || {} as Authentication.Session;
        session.revokeAuthEvents = true;
        Offline.setSession(session);
    }
    /**
     * Enabled auth change listener.
     */
    static envokeAuthEvents(): void {
        const session = Offline.session
            || {} as Authentication.Session;
        session.revokeAuthEvents = false;
        Offline.setSession(session);
    }
    /**
     * Listen for auth changes for current user.
     * This function will be triggered during sign in,
     * sign out.
     * @param onChange function envoked when auth events are triggered.
     */
    static onAuthChange(
        onChange: (user: firebase.User | null) => void
    ): void {
        // enable offline authentication services
        if (Offline.isOffline) onChange(Offline.cache?.profile);
        firebase.auth().onAuthStateChanged(async (user) => {
            // get current auth instance
            if (!(Offline.session
                && Offline.session.revokeAuthEvents)) {
                if (user) await User.init(user, onChange);
                // transfer further processng to app handler
                onChange(user);
            }
        })
    }
    /**
     * Get current users `photoURL` regardless of the auth provider with cropping enabled
     * for users that did not sign-up with the `password` provider.
     *  If the specified `width`and `height` is set, the cropped image url will be generated.
     * @param width The requested `width` of the profile image.
     * @param height The requested `height` of the profile image.
     * @param uid The user id to lookup for the requested profile image, if not
     * specified the current logged in user will be used.
     */
    static getPhotoURL(
        width?: number,
        height?: number,
        uid?: string
    ): Promise<string | null> {
        return new Promise(
            async function (resolve, reject) {
                const provider = User.current?.providerData[0];
                // identify the users photoURL
                if (User.current?.photoURL && provider && provider.providerId !== "password") {
                    // user signed in with a custom provider
                    if (width || height) {
                        // user requested a custom resolution for the signed-in provider profile image
                        switch (provider.providerId) {
                            case "facebook.com":
                                return resolve(`${User.current.photoURL}?width=${width}&height=${height}`);
                            case "google.com":
                                return resolve(User.current.photoURL.replace("s96-c", `s${width}-c`));
                            default:
                                break;
                        }
                    }
                }
                // users photoURL is already set in its firebase auth object
                if (User.current?.photoURL) return resolve(User.current.photoURL);
                // check if users photoURL is available in their custom profile data
                // for users signed-in with the email/password provider, url based
                // image croping is not available for now.
                // get profile image refference
                const globalUserRecord = await Firestore
                    .collection('users').doc(uid || User.current?.uid).get();
                if (!globalUserRecord.exists || !globalUserRecord.data()?.photoUrl) return resolve(null);
                // check if profile image is cached
                // update profile img cache
                return resolve(await GCPStorage.getObjectURL(
                    globalUserRecord.data()?.photoUrl));
            }
        )
    }
    /**
     * Sets or updates users profile image.
     * Note: only JPG or JPEG images are allowed
     * @param image file object to upload
     * @param onUpload function envoked when upload is complete
     */
    static setProfileImg(
        image: File,
        onUpload: (taskState: Storage.UploadTaskState) => void
    ): Promise<void> {
        return new Promise(
            async function (resolve, reject) {
                // check image type
                if (!(image.type === "image/jpg"
                    || image.type === "image/jpeg")) {
                    reject(Logger.error({
                        code: 'image-requirments-not-meet',
                        origin: Authentication.origin,
                        description: "only jpg or jpeg files allowed as profile images"
                    } as Event));
                }
                // get user profile
                const profile = await User.getProfile();
                // check if profime image already exists
                if (profile && profile.profileImg) {
                    // update profile image
                    await GCPStorage.delete(profile.profileImg)
                        // hanfle errors
                        .catch(error => reject(User.error(error)))
                }
                // upload profime image
                const location = await GCPStorage.uploadFile(image,
                    `users/${User.current?.uid}/`, undefined, onUpload)
                    // hanfle errors
                    .catch(error => reject(User.error(error)))
                // update profile record
                await User.setProfile({ profileImg: location });
                // update successful
                return resolve();
            }
        )
    }
    /**
     * Get current logined user with custome created details.
     * @param userID users unique id, by default its set 
     * to currently logged in user
     */
    static async getProfile(
        userID: string | undefined = User.current?.uid
    ): Promise<any> {
        if (User.current) {
            // check if user profile already exists
            const profile = await Firestore
                .collection('users').doc(userID).get();
            if (profile.exists) return profile.data();
        }
        return null;
    }
    /**
     * Create Credientials
     * @param email User email address
     * @param password User password
     */
    static emailAuthProviderCredential(
        email: string,
        password: string
    ): firebase.auth.AuthCredential {
        return firebase.auth.EmailAuthProvider
            .credential(email, password);
    }
    /**
     * Updates currently logged in users display name.
     * @param displayName New displayName
     */
    static updateDisplayName(
        displayName: string
    ): Promise<void> {
        return new Promise(
            async (resolve, reject) => {
                await User.current
                    ?.updateProfile({ displayName: displayName })
                    .then(_ => resolve(),
                        error => reject(User.error(error)));
            }
        )
    }
    /**
     * Updates the user's email address.
     * An email will be sent to the original email address 
     * (if it was set) that allows to revoke the email address 
     * change, in order to protect them from account hijacking.
     * Important: this is a security sensitive operation that 
     * requires the user to have recently signed in. If this 
     * requirement isn't met, ask the user to authenticate 
     * again and then call {@link firebase.User.reauthenticateWithCredential}.
     * @param email New email address
     */
    static updateEmail(
        email: string
    ): Promise<void> {
        return new Promise(
            async (resolve, reject) => {
                await User.current?.updateEmail(email)
                    .then(_ => resolve(),
                        error => reject(User.error(error)));
            }
        )
    }
    /**
     * Updates the user's password.
     * Updates the user's password.
     * Important: this is a security sensitive operation that 
     * requires the user to have recently signed in. If this 
     * requirement isn't met, ask the user to authenticate again..
     * @param password 
     * @returns 
     */
    static updatePassword(
        password: string
    ): Promise<void> {
        return new Promise(
            async (resolve, reject) => {
                await User.current?.updatePassword(password)
                    .then(_ => resolve(),
                        error => reject(User.error(error)));
            }
        )
    }
    /**
     * Creates / Updates current users profile.
     * @param profile New user profile data
     */
    static setProfile(
        profile: any
    ): Promise<void> {
        return new Promise(
            async (resolve, reject) => {
                // get current users profile data (if any)
                const currentProfile = await Firestore
                    .collection('users')
                    .doc(User.current?.uid).get();
                // check if user already has a profile
                if (currentProfile.exists)
                    // update users profile data
                    await Firestore.collection('users')
                        .doc(User.current?.uid).update(profile)
                        .then(_ => resolve(),
                            error => reject(User.error(error)));
                else
                    // create new profile 
                    await Firestore.collection('users')
                        .doc(User.current?.uid).set(profile)
                        .then(_ => resolve(),
                            error => reject(User.error(error)));
            }
        )
    }
    /**
     * Listen for changes to user custom metadata
     * @param lisnter Profile change handler
     */
    static onProfileChange(
        lisnter: Function
    ): Function {
        if (!User.isLoggedIn)
            throw new Error('no user logged in');
        // add listner to user profile document
        return Firestore
            .collection('users').doc(User.uid)
            .onSnapshot(snap => lisnter(snap.data()));
    }
    /**
     * Creates a new user account associated with 
     * the specified email address and password.
     *
     * On successful creation of the user account, 
     * this user will also be
     * signed in to your application.
     *
     * User account creation can fail if the account 
     * already exists or the password
     * is invalid.
     *
     * Note: The email address acts as a unique 
     * identifier for the user and
     * enables an email-based password reset.  
     * This function will create
     * a new user account and set the initial
     *  user password.
     *
     * <h4>Error Codes</h4>
     * <dl>
     * <dt>auth/email-already-in-use</dt>
     * <dd>Thrown if there already exists an account 
     * with the given email
     *     address.</dd>
     * <dt>auth/invalid-email</dt>
     * <dd>Thrown if the email address is not valid.</dd>
     * <dt>auth/operation-not-allowed</dt>
     * <dd>Thrown if email/password accounts are not 
     * enabled. Enable email/password
     * accounts in the Firebase Console, under the 
     * User tab.</dd>
     * <dt>auth/weak-password</dt>
     * <dd>Thrown if the password is not strong enough.</dd>
     * </dl>
     * @param email The user's email address.
     * @param password The user's chosen password.
     */
    static async create(
        email: string,
        password: string
    ): Promise<firebase.auth.UserCredential> {
        return new Promise(
            async (resolve, reject) => {
                // perform insight analytics logging
                firebase.auth().createUserWithEmailAndPassword(email, password)
                    .then(user => resolve(user),
                        error => reject(error))
            }
        )
    }
    /**
     * Creates a new user account associated with 
     * the specified provider.
     *
     * @param provider Sign-in provider
     * @param rediect Use redirect method to sign-in, popup otherwise.\
     * @param scopes Identify additional OAuth 2.0 scopes that you want to request from the authentication provider
     * @param customParams Specify additional custom OAuth provider parameters that you want to send with the OAuth request.
     */
    static async createWithProvider(
        provider: "facebook" | "google",
        rediect: boolean,
        disableTrustedProvider: boolean = false,
        scopes?: Array<string>,
        customParams?: any
    ): Promise<firebase.auth.UserCredential> {
        return new Promise(
            async (resolve, reject) => {
                var providerObject: firebase.auth.FacebookAuthProvider | firebase.auth.GoogleAuthProvider;
                if (provider === "facebook") providerObject = new firebase.auth.FacebookAuthProvider();
                else providerObject = new firebase.auth.GoogleAuthProvider();
                // configure custom scopes
                if (scopes)
                    for (const scope of scopes)
                        providerObject.addScope(scope);
                // configure custom paramters
                if (customParams) providerObject.setCustomParameters(customParams);
                // identify registration method
                if (rediect) {
                    // redirect user to new window to complete registration
                    firebase.auth().signInWithRedirect(providerObject);
                    firebase.auth()
                        .getRedirectResult()
                        .then((result) => resolve(result))
                        .catch((error) => reject(error));
                } else {
                    firebase
                        .auth()
                        .signInWithPopup(providerObject)
                        .then((result) => resolve(result))
                        .catch((error) => reject(error));
                }
            }
        )
    }
    /**
     * Sign up user and with custome profile data,
     * after the custom profile is created the
     * user is automatically signed in.
     * 
     * Note: if auto login is revoked the app wont 
     * be informed of the
     * newly created users sign in, so user specific 
     * operations can be done quitely,
     * the app will start to recieve auth changes 
     * after signInWithEmailAndPassword 
     * is called.
     * 
     * Newly created users are sent email verifications 
     * by default and also logged in by default.
     * @param email User email address
     * @param password User password
     * @param profile User custom profile data
     * @param loginOnSuccess Automatically log in user
     * after registration is compelte. By default
     * all users are signed in after registration.
     * @param sendVerification Send a verification email
     * to users email address after registration is complete.
     * By default all new registrations will recieve an email
     * from app domain for email verification.
     */
    static createCustom(
        email: string,
        password: string,
        profile: any,
        loginOnSuccess: boolean = true,
        sendVerification: boolean = true
    ): Promise<string | undefined> {
        return new Promise(
            async (resolve, reject) => {
                // revoke on auth change events temporaraly
                User.revokeAuthEvents();
                // create new user
                User.create(email, password)
                    .then(async credentials => {
                        // create user profile
                        await User.setProfile(profile);
                        // send email verification
                        if (sendVerification)
                            credentials.user?.sendEmailVerification(
                                { url: `${window.location.origin}` });
                        // refresh user
                        // allow auth changes to envoke app handler
                        User.envokeAuthEvents();
                        if (loginOnSuccess) {
                            await firebase.auth().signOut();
                            // refresh user
                            await User.signInWithEmailAndPassword(email, password);
                        }
                        // registration complete
                        resolve(credentials.user?.uid);
                    })
                    .catch(error => reject(User.error(error)));

            }
        )
    }
    /**
     * Generate unique authorization key for mediated
     * user registrations. Authorization keys allow
     * control over user registrations.
     * 
     * Once a user has used a authorization key it can
     * not be used again
     */
    static generateAuthorizationKey(): Promise<string> {
        return new Promise(
            async (resolve, reject) => {
                if (User.isLoggedIn) {
                    const key = CryptoJS.AES.encrypt(`${Date.now()}`,
                        `${User.displayName}${User.uid}`).toString();
                    // store key on current users profile date
                    await User.setProfile({ authKey: Firestore.fieldValue.arrayUnion(key) })
                        .then(_ => resolve(key))
                } else reject(User.error({ code: "auth/no-logged-in-user" }));
            }
        )
    }
    /**
     * Check weather the provided authorization key
     * is valid.
     * @param key Authorization Key
     */
    static verifyAuthorizationKey(
        key: string
    ): Promise<boolean> {
        return new Promise(
            async (resolve, reject) => {
                // identify authorization key
                const keyHolder = await Firestore.collection('users')
                    .where("authKey", "array-contains", key).get();
                if (keyHolder.empty) resolve(false);
                else {
                    // remove auth key
                    await Firestore.collection('users')
                        .doc(keyHolder?.docs?.pop()?.id).update({
                            authKey: Firestore.fieldValue.arrayRemove(key)
                        }).then(_ => resolve(true))
                }
            }
        )
    }
    /**
     * Sign User Out of App, This will trigger an app 
     * OAuth change event.
     * Note: if any user is currently signed they will
     * automatically singed out before signing in the 
     * new user.
     * @param email users email
     * @param password users registered password
     * @param trustedDevice save user profile on this 
     * device for possiable ofline capabilities.
     */
    static signInWithEmailAndPassword(
        email: string,
        password: string,
        trustedDevice: boolean = false
    ): Promise<void> {
        return new Promise(
            async (resolve, reject) => {
                if (User.isLoggedIn) await User.signOut();
                if (trustedDevice) {
                    // mark current device as trusted
                    const session = Offline.session
                        || {} as Authentication.Session;
                    session.addAsTrustedDevice = true;
                    Offline.setSession(session);
                }
                // perform insight analytics loging
                await firebase.auth()
                    .signInWithEmailAndPassword(email, password)
                    .then(_ => resolve(), error => {
                        //if (error.code === 'auth/user-not-found') {
                        // To DO: check is user account is in-complete and complete
                        // registration
                        //} else 
                        reject(User.error(error))
                    })
                return true;
            }
        )
    }
    /**
     * Re-authenticates a user using a fresh credential. Use before 
     * operations such as {@link core.auth.updatePassword} that 
     * require tokens from recent sign-in attempts.
     * @param email Users email address
     * @param password Users password
     */
    static reauthenticateWithCredential(
        email: string,
        password: string
    ): Promise<void> {
        return new Promise(
            async (resolve, reject) => {
                // create firebase auth cridentials
                const credientials = User
                    .emailAuthProviderCredential(email, password);
                // re-authenticate user
                User.current?.reauthenticateWithCredential(credientials)
                    .then(_ => resolve(), error => reject(User.error(error)))
            }
        )
    }
    
    /**
     * Check if an email is already associated with an account.
     * @param email Email address to check
     * @param provider list of auth providers to lookup
     */
    static isEmailAvailable(
        email: string,
        providers: Array<"password" | "facebook.com" | "google.com">
    ): Promise<boolean> {
        return new Promise(
            async (resolve, reject) => {
                // to validate an email address association
                // a list of sign in methods 
                const signInMethods = await firebase.auth()
                    .fetchSignInMethodsForEmail(email)
                    .catch(error => User.error(error));
                // cycle through each provider and find a match
                for (const provider of providers)
                    if (signInMethods.includes(provider))
                        return resolve(false);
                // perform manual checking on the users collection
                // for non email/password providers
                return resolve(true);
            }
        )
    }
    /**
     * Check if a username is already in use by a account
     * @param username Username to lookup
     * @returns 
     */
    static isUsernameAvailable(
        username: string
    ): Promise<boolean> {
        return new Promise(
            async (resolve, reject) => {
                const user = await firebase.firestore().collection('users').where('username', "==", username).get();
                if (user.empty) return resolve(true);
                return resolve(false);
            }
        )
    }
    /**
     * Send user email verification on there provided email,
     * Note: Email Verification only works if the user logs in
     * with email and password and not other signin methods
     */
    static sendEmailVerification(): Promise<void> {
        return new Promise(
            async (resolve, reject) => {
                await User.current?.sendEmailVerification()
                    .then(_ => resolve(),
                        error => reject(User.error(error)))
            }
        )
    }
    /**
     * Send a password reset email.
     * @param email Email address of user.
     * If no email address is provided the current
     * user email address will be used by default.
     */
    static sendPasswordResetEmail(
        email?: string
    ): Promise<void> {
        return new Promise(
            (resolve, reject) => {
                firebase.auth().sendPasswordResetEmail(email
                    || User.email as string)
                    .then(_ => resolve())
                    .catch(_ => reject())
            }
        )
    }
    /**
     * Sign out currently logged in user.
     */
    static signOut(): Promise<void> {
        return new Promise(
            async (resolve, reject) => {
                firebase.auth().signOut()
                    .then(_ => resolve(),
                        error => reject(User.error(error)))
            }
        )
    }
    /**
     * Remove user account
     */
    static delete(): Promise<void> {
        return new Promise(
            async (resolve, reject) => {
                if (!User.isLoggedIn) return;
                // remove any user profile data
                await Firestore.collection('users')
                    .doc(User.uid).delete();
                await User.current?.delete()
                    .then(_ => resolve(),
                        error => reject(User.error(error)))
            }
        )
    }
    /**
     * Generate Error prompt for specific error codes.
     * @param error Error metadata
     */
    private static error(
        error: any
    ): string {
        const metadata: Event = {
            code: error.code,
            origin: Authentication.origin,
            description: ""
        }
        switch (error.code) {
            case "auth/wrong-password":
                metadata.description = "The provided password for user is incorrect."
                metadata.code = 'wrong-password';
            case "auth/email-already-in-use":
                metadata.description =
                    "The provided email address is already registered by a user."
                metadata.code = 'email-already-in-use';
            case "auth/invalid-email":
                metadata.description =
                    "The provided email addresss is invalid"
                metadata.code = 'invalid-email';
            case "auth/operation-not-allowed":
                metadata.description =
                    "The requested operation is not allowed for user"
                metadata.code = 'operation-not-allowed';
            case "auth/weak-password":
                metadata.description =
                    "The entered password has a weak structure."
                metadata.code = 'weak-password';
            case "auth/user-disabled":
                metadata.description =
                    "The registered user has been disabled by admin"
                metadata.code = 'user-disabled';
            case "auth/user-not-found":
                metadata.description =
                    "The requested user account was not found"
                metadata.code = 'user-not-found';
            case "auth/no-user-profile-data":
                metadata.description =
                    "The registered user does not have any profile metadata."
                metadata.code = 'no-user-profile-data';
            case "auth/not-logged-in-user":
                metadata.description =
                    "There are currently no active user"
                metadata.code = "no-logged-in-user";
            default:
                metadata.description =
                    "An Unknwon error as occured, review stack trace"
                metadata.code = 'no-appropriate-code';
        }
        // inform support logger of error
        Logger.error(metadata);
        return metadata.code;
    }
}